import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import * as backupsActions from "modules/backups/actions";
import {
  FULL_BACKUP_INTERVALS_TYPES,
  FULL_BACKUP_INTERVALS_TYPES_LABELS,
  RETENTION_TYPES,
  RETENTION_TYPES_LABELS,
  TableBackupsWorkload,
  WORKLOADS_TYPES,
  WORKLOADS_TYPES_LABELS
} from "modules/backups/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useParams } from "react-router-dom";
import { DIALOG_TYPES } from "./types";

import { format } from "date-fns";
import dayjs from "dayjs";
import {
  areBackupsWorkloadsLoadingSelector,
  backupsWorkloadDetailsSelector,
  backupsWorkloadsTableSelector,
  isBackupsWorkloadCreatingSelector,
  isBackupsWorkloadDeletingSelector,
  isBackupsWorkloadDetailsLoadingSelector,
  isBackupsWorkloadEditingSelector
} from "modules/backups/selectors";
import { instancesSelector } from "modules/instances/selectors";
import moment from "moment-timezone";
import { roundToNearest15Minutes } from "utils/formatTime";
import { getSelectOption } from "utils/getSelectOption";
import { array, number, string } from "yup";
import { ERROR_MESSAGES, ROUTES } from "../../constants";
import { Badge } from "@mui/material";
// import moment from "moment-timezone";

// const timeZones = moment.tz.names();

const POLL_ID_PREFIX = "BACKUPS_WORKLOADS";

const POLL_IDS = {
  vms: "VMS",
  backups_workloads: "BACKUPS_WORKLOADS"
};

const title = "Backups Workloads";

const tableWorkloadsColumns: TableColumn<TableBackupsWorkload>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "status", label: "Status" },
  { key: "workload_type", label: "Workload Type" },
  {
    key: "created_at",
    label: "Created",
    sortingType: TABLE_SORTING_TYPES.DATE
  },
  {
    key: "updated_at",
    label: "Updated",
    sortingType: TABLE_SORTING_TYPES.DATE
  }
];

export const BackupsWorkloads: FC = () => {
  const dispatch = useDispatch();
  const matchParams = useParams<{
    organizationId: string;
    projectId: string;
    regionId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const virtualMachines = useSelector(instancesSelector);
  const workloads = useSelector(backupsWorkloadsTableSelector);

  const areBackupsWorkloadsLoading = useSelector(
    areBackupsWorkloadsLoadingSelector
  );

  const isBackupsWorkloadCreating = useSelector(
    isBackupsWorkloadCreatingSelector
  );
  const isBackupsWorkloadEditing = useSelector(
    isBackupsWorkloadEditingSelector
  );
  const isBackupsWorkloadDeleting = useSelector(
    isBackupsWorkloadDeletingSelector
  );

  const isWorkloadsOperationInProgress =
    isBackupsWorkloadCreating ||
    isBackupsWorkloadEditing ||
    isBackupsWorkloadDeleting;
  const previousIsWorkloadsOperationInProgress = usePrevious(
    isWorkloadsOperationInProgress
  );

  const workloadDetails = useSelector(backupsWorkloadDetailsSelector);
  const isWorkloadDetailsLoading = useSelector(
    isBackupsWorkloadDetailsLoadingSelector
  );
  const previousIsWorkloadDetailsLoading = usePrevious(
    isWorkloadDetailsLoading
  );

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_WORKLOAD, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
    // dispatch(backupsActions.clearWorkloadDetails());
  }, [dialog]);

  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Backups Workloads",
      url: generatePath(ROUTES.BACKUPS_WORKLOADS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    }
  ];

  const generateTableItemURL = useCallback(
    (id: string) => {
      return generatePath(ROUTES.BACKUPS_WORKLOAD, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        workloadId: id
      });
    },
    [matchParams.projectId, matchParams.organizationId, matchParams.regionId]
  );

  const handleCreateWorkloadButtonClick = useCallback(() => {
    setDialog({
      type: DIALOG_TYPES.CREATE_WORKLOAD,
      isOpened: true
    });
  }, []);

  const handleEditWorkloadMenuItemClick = useCallback(
    (id: string) => {
      setSelectedItemId(id);
      dispatch(
        backupsActions.getBackupsWorkloadDetails.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: id
        })
      );
      setDialog({
        type: DIALOG_TYPES.EDIT_WORKLOAD,
        isOpened: true
      });
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleDeleteWorkloadMenuItemClick = useCallback((id: string) => {
    setSelectedItemId(id);
    setDialog({
      type: DIALOG_TYPES.DELETE_WORKLOAD,
      isOpened: true
    });
  }, []);

  const tableActions: TableRowActionsMenuItem<TableBackupsWorkload>[] = [
    {
      label: "Edit",
      handler: handleEditWorkloadMenuItemClick
    },
    {
      label: "Delete",
      handler: handleDeleteWorkloadMenuItemClick
    }
  ];

  const handleConfirmCreateWorkload = useCallback(
    (data: {
      name: string;
      description: string;
      instances: SelectOption[];
      workload_type: string;
      retention_policy_type: RETENTION_TYPES;
      retention_snapshots: number;
      retention_days: number;
      fullbackup_interval_rdo: FULL_BACKUP_INTERVALS_TYPES;
      snapshots_number: number;
      pause_at_snapshot: boolean;
      scheduler_enabled: boolean;
      start_date: string;
      end_date: string;
      start_time: string;
      timezone?: SelectOption;
      interval: number;
    }) => {
      dispatch(
        backupsActions.createBackupsWorkload.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          data: {
            name: data.name,
            description: data.description,
            workload_type_id: data.workload_type,
            // pause_at_snapshot: data.pause_at_snapshot,
            instances: data.instances.map((instance) => ({
              "instance-id": instance.value
            })),
            jobschedule: {
              retention_policy_type: data.retention_policy_type,
              retention_policy_value:
                data.retention_policy_type === RETENTION_TYPES.DAYS
                  ? data.retention_days
                  : data.retention_snapshots,
              timezone: "UTC",
              // timezone: data.timezone.value,
              start_date: format(new Date(data.start_date), "MM/dd/yyyy"),
              end_date:
                data.end_date !== null
                  ? format(new Date(data.end_date), "MM/dd/yyyy")
                  : "",
              start_time: format(new Date(data.start_time), "hh:mm a"),
              interval: `${data.interval} hr`,
              enabled: data.scheduler_enabled,
              fullbackup_interval:
                data.fullbackup_interval_rdo ===
                FULL_BACKUP_INTERVALS_TYPES.ALWAYS
                  ? "0"
                  : data.fullbackup_interval_rdo ===
                      FULL_BACKUP_INTERVALS_TYPES.NEVER
                    ? "-1"
                    : String(data.snapshots_number)
            }
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId
    ]
  );

  const handleConfirmEditWorkload = useCallback(
    (data: {
      name?: string;
      description?: string;
      instances?: SelectOption[];
      workload_type?: string;
      retention_policy_type?: RETENTION_TYPES;
      retention_snapshots?: number;
      retention_days?: number;
      fullbackup_interval_rdo?: FULL_BACKUP_INTERVALS_TYPES;
      snapshots_number?: number;
      pause_at_snapshot?: boolean;
      scheduler_enabled?: boolean;
      start_date?: string;
      end_date?: string;
      start_time?: string;
      timezone?: SelectOption;
      interval?: number;
    }) => {
      if (selectedItemId) {
        dispatch(
          backupsActions.editBackupsWorkload.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            id: selectedItemId,
            data: {
              name: data.name,
              description: data.description,
              workload_type_id: data.workload_type,
              instances: data.instances?.map((instance) => ({
                "instance-id": instance.value
              })),
              jobschedule: {
                retention_policy_type: data.retention_policy_type,
                retention_policy_value:
                  data.retention_policy_type === RETENTION_TYPES.DAYS
                    ? data.retention_days
                      ? data.retention_days
                      : undefined
                    : data.retention_snapshots
                      ? data.retention_snapshots
                      : undefined,
                timezone: data.timezone?.value,
                start_date:
                  data.start_date &&
                  format(new Date(data.start_date), "MM/dd/yyyy"),
                end_date:
                  data.end_date !== null
                    ? data.end_date &&
                      format(new Date(data.end_date), "MM/dd/yyyy")
                    : "",
                start_time:
                  data.start_time &&
                  format(new Date(data.start_time), "hh:mm a"),
                interval: data.interval ? `${data.interval} hr` : undefined,
                enabled: data.scheduler_enabled,
                fullbackup_interval:
                  data.fullbackup_interval_rdo ===
                  FULL_BACKUP_INTERVALS_TYPES.ALWAYS
                    ? "0"
                    : data.fullbackup_interval_rdo ===
                        FULL_BACKUP_INTERVALS_TYPES.NEVER
                      ? "-1"
                      : String(data.snapshots_number)
              }
            }
          })
        );
        dispatch(backupsActions.clearWorkloadDetails());
        handleCloseDialog();
      }
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      selectedItemId
    ]
  );

  const handleConfirmDeleteWorkload = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        backupsActions.deleteBackupsWorkload.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId
  ]);

  const timeZones = moment.tz.names();
  const currentTimeZone = moment.tz.guess();

  const previousSelectedItemId = usePrevious(selectedItemId);
  const deletingItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const deletingWorkloadName = workloads?.find(
    (workload) => workload.id === deletingItemId
  )?.name;

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_WORKLOAD]: {
      onConfirm: handleConfirmCreateWorkload,
      title: "Create Workload",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string().required().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME)
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string().max(1024, ERROR_MESSAGES.MAX_BACKUPS_DESCRIPTION)
        },
        {
          name: "instances",
          type: FIELD_TYPES.MULTISELECT,
          // helperText:
          //   "The virtual machine can only be assigned to one workload.",
          label: "Instances",
          options: virtualMachines?.map((virtualMachine) =>
            getSelectOption(virtualMachine, "name", "id")
          ),
          rules: array(selectOptionSchema)
        },
        {
          name: "instancesInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return (fieldValues.instances as SelectOption[])?.length === 0;
          },
          label:
            "ℹ️ Ensure that the instance you choose isn't already assigned to any existing workloads"
        },
        {
          name: "instancesInfo2",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return (fieldValues.instances as SelectOption[])?.length !== 0;
          },
          label:
            "⚠️ At least one instance, should be selected to be the part of workload definition."
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Backup workload type and retention policy type:"
        },
        {
          name: "workload_type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Workload type",
          helperText: `Choose workload type:\nSerial - runs backups in specific order\nParallel - runs backups simultaneously.`,
          options: Object.keys(WORKLOADS_TYPES_LABELS).map((x) => ({
            label: WORKLOADS_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: WORKLOADS_TYPES.SERIAL,
          rules: string()
        },
        // {
        //   name: "divider3",
        //   type: FIELD_TYPES.DIVIDER,
        //   label: "Retention policy type"
        // },
        {
          name: "retention_policy_type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Retention policy type",
          helperText: "Choose retention policy type",
          options: Object.keys(RETENTION_TYPES_LABELS).map((x) => ({
            label: RETENTION_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: RETENTION_TYPES.SNAPSHOTS,
          rules: string()
        },
        {
          name: "retention_snapshots",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 30,
          label: "Number of backups to keep",
          suffix: "backups",
          min: 1,
          max: 365,
          isHidden: (fieldValues) => {
            return (
              fieldValues.retention_policy_type !== RETENTION_TYPES.SNAPSHOTS
            );
          },
          rules: number()
            .integer()
            .min(1, `${ERROR_MESSAGES.BACKUPS_SNAPSHOTS_RETENTION}.`)
            .max(365, `${ERROR_MESSAGES.BACKUPS_SNAPSHOTS_RETENTION}.`)
        },
        {
          name: "retention_days",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 30,
          label: "Number of days to retain backups",
          suffix: "days",
          min: 1,
          max: 365,
          isHidden: (fieldValues) => {
            return fieldValues.retention_policy_type !== RETENTION_TYPES.DAYS;
          },
          rules: number()
            .integer()
            .min(1, `${ERROR_MESSAGES.BACKUPS_DAYS_RETENTION}.`)
            .max(365, `${ERROR_MESSAGES.BACKUPS_DAYS_RETENTION}.`)
        },
        {
          name: "divider2",
          type: FIELD_TYPES.DIVIDER,
          label: "Full backup interval:"
        },
        {
          name: "fullbackup_interval_rdo",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Full Backup Interval",
          helperText: "Select full backup frequency",
          options: Object.keys(FULL_BACKUP_INTERVALS_TYPES_LABELS).map((x) => ({
            label: FULL_BACKUP_INTERVALS_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: FULL_BACKUP_INTERVALS_TYPES.ALWAYS,
          rules: string()
        },
        {
          name: "snapshots_number",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 1,
          label: "Number of incremental backups",
          suffix: "backups",
          min: -1,
          isHidden: (fieldValues) => {
            return (
              fieldValues.fullbackup_interval_rdo !==
              FULL_BACKUP_INTERVALS_TYPES.SNAPSHOTS
            );
          },
          rules: number().integer()
        },
        // {
        //   name: "pause_at_snapshot",
        //   type: FIELD_TYPES.TOGGLE,
        //   defaultValue: false,
        //   label: "Pause VM"
        // },
        {
          name: "divider3",
          type: FIELD_TYPES.DIVIDER,
          label: "Schedule Settings"
        },
        {
          name: "scheduler_enabled",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: true,
          fullWidth: true,
          label: "Enable scheduler"
        },
        {
          name: "start_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "Start Date",
          helperText: `Enter start date.`,
          disablePast: true,
          defaultValue: dayjs(Date.now())
          // isHidden: (fieldValues) => {
          //   return !fieldValues.scheduler_enabled;
          // }
        },
        {
          name: "end_date",
          type: FIELD_TYPES.DATE_PICKER,
          disablePast: true,
          label: "End Date",
          helperText: `Enter end date.\nLeave the field empty to indicate 'never'.`,
          defaultValue: dayjs(Date.now()).add(1, "year") // next year
          // isHidden: (fieldValues) => {
          //   return !fieldValues.scheduler_enabled;
          // }
        },
        {
          name: "start_time",
          type: FIELD_TYPES.STATIC_TIME_PICKER,
          label: "Choose UTC time of interval start",
          defaultValue: roundToNearest15Minutes(dayjs())
          // isHidden: (fieldValues) => {
          //   return !fieldValues.scheduler_enabled;
          // }
        },
        // {
        //   name: "timezone",
        //   type: FIELD_TYPES.SELECT,
        //   label: "Time Zone",
        //   variant: "outlined",
        //   options: timeZones.map((timeZone) => ({
        //     label:
        //       timeZone === currentTimeZone ? `${timeZone} (Current)` : timeZone,
        //     value: timeZone
        //   })),
        //   defaultValue: {
        //     label: currentTimeZone,
        //     value: currentTimeZone
        //   }
        //   // isHidden: (fieldValues) => {
        //   //   return !fieldValues.scheduler_enabled;
        //   // }
        // },
        // {
        //   name: "start_time2",
        //   type: FIELD_TYPES.TIME_PICKER,
        //   label: "Choose time of interval start",
        //   labelPicker: "Choose time of interval start",
        //   defaultValue: roundToNearest15Minutes(dayjs()),
        //   isHidden: (fieldValues) => {
        //     return !fieldValues.scheduler_enabled;
        //   }
        // },
        {
          name: "interval",
          type: FIELD_TYPES.NUMBER,
          defaultValue: 24,
          label: "Repeat every (in hrs)",
          suffix: "hrs",
          min: 1,
          rules: number().integer()
        }
      ]
    },
    [DIALOG_TYPES.EDIT_WORKLOAD]: {
      onConfirm: handleConfirmEditWorkload,
      title: "Edit Workload",
      isLoading: isWorkloadDetailsLoading,
      // isLoading: !workloadDetails,
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string().required().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME),
          defaultValue: workloadDetails?.name
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: workloadDetails?.description,
          rules: string().max(1024, ERROR_MESSAGES.MAX_BACKUPS_DESCRIPTION)
        },
        {
          name: "instances",
          type: FIELD_TYPES.MULTISELECT,
          label: "Instances",
          options: virtualMachines?.map((virtualMachine) =>
            getSelectOption(virtualMachine, "name", "id")
          ),
          defaultValue: workloadDetails?.instances.map((vm) =>
            getSelectOption(vm, "name", "id")
          ),
          rules: array(selectOptionSchema)
        },
        {
          name: "instancesInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return !!(
              fieldValues.instances &&
              (fieldValues.instances as SelectOption[]).length === 0
            );
          },
          label:
            "ℹ️ Ensure that the instance you choose isn't already assigned to any existing workloads"
        },
        {
          name: "instancesInfo2",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return !!(
              fieldValues.instances &&
              (fieldValues.instances as SelectOption[]).length !== 0
            );
          },
          label:
            "⚠️ At least one instance, should be selected to be the part of workload definition."
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Retention policy type:"
        },
        {
          name: "retention_policy_type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Retention policy type",
          helperText: "Choose retention policy type",
          options: Object.keys(RETENTION_TYPES_LABELS).map((x) => ({
            label: RETENTION_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: workloadDetails?.retention_policy_type,
          rules: string()
        },
        {
          name: "retention_snapshots",
          type: FIELD_TYPES.NUMBER,
          label: "Number of backups to keep",
          suffix: "backups",
          defaultValue: workloadDetails?.retention_policy_value || 30,
          min: 1,
          max: 365,
          isHidden: (fieldValues) => {
            return (
              fieldValues.retention_policy_type !== RETENTION_TYPES.SNAPSHOTS
            );
          },
          rules: number()
            .integer()
            .min(1, `${ERROR_MESSAGES.BACKUPS_SNAPSHOTS_RETENTION}.`)
            .max(365, `${ERROR_MESSAGES.BACKUPS_SNAPSHOTS_RETENTION}.`)
        },
        {
          name: "retention_days",
          type: FIELD_TYPES.NUMBER,
          defaultValue: workloadDetails?.retention_policy_value || 30,
          label: "Number of days to retain backups",
          suffix: "days",
          min: 1,
          max: 365,
          isHidden: (fieldValues) => {
            return fieldValues.retention_policy_type !== RETENTION_TYPES.DAYS;
          },
          rules: number()
            .integer()
            .min(1, `${ERROR_MESSAGES.BACKUPS_DAYS_RETENTION}.`)
            .max(365, `${ERROR_MESSAGES.BACKUPS_DAYS_RETENTION}.`)
        },
        {
          name: "divider2",
          type: FIELD_TYPES.DIVIDER,
          label: "Full backup interval:"
        },
        {
          name: "fullbackup_interval_rdo",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Full Backup Interval",
          helperText: "Select full backup frequency",
          options: Object.keys(FULL_BACKUP_INTERVALS_TYPES_LABELS).map((x) => ({
            label: FULL_BACKUP_INTERVALS_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: () => {
            if (Number(workloadDetails?.fullbackup_interval) === 0)
              return FULL_BACKUP_INTERVALS_TYPES.ALWAYS;
            if (Number(workloadDetails?.fullbackup_interval) === -1)
              return FULL_BACKUP_INTERVALS_TYPES.NEVER;
            return FULL_BACKUP_INTERVALS_TYPES.SNAPSHOTS;
          },
          rules: string()
        },
        {
          name: "snapshots_number",
          type: FIELD_TYPES.NUMBER,
          defaultValue: () => {
            if (Number(workloadDetails?.fullbackup_interval) <= 0) return 0;
            else return Number(workloadDetails?.fullbackup_interval);
          },
          label: "Number of incremental backups",
          suffix: "backups",
          min: -1,
          isHidden: (fieldValues) => {
            return (
              fieldValues.fullbackup_interval_rdo !==
              FULL_BACKUP_INTERVALS_TYPES.SNAPSHOTS
            );
          },
          rules: number().integer()
        },
        // {
        //   name: "divider2",
        //   type: FIELD_TYPES.DIVIDER,
        //   label: "Full backup interval:"
        // },
        // {
        //   name: "pause_at_snapshot",
        //   type: FIELD_TYPES.TOGGLE,
        //   defaultValue: workloadDetails,
        //   label: "Pause VM"
        // },
        {
          name: "divider3",
          type: FIELD_TYPES.DIVIDER,
          label: "Schedule Settings"
        },
        {
          name: "scheduler_enabled",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: workloadDetails?.schedule_enabled,
          fullWidth: true,
          label: "Enable scheduler"
        },
        {
          name: "start_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "Start Date",
          helperText: `Edit start date.`,
          defaultValue: dayjs(workloadDetails?.start_date),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        },
        {
          name: "end_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "End Date",
          helperText: `Edit end date.\nLeave the field empty to indicate 'never'.`,
          defaultValue:
            workloadDetails?.end_date === "No End" || !workloadDetails?.end_date
              ? null
              : dayjs(workloadDetails?.end_date),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        },
        {
          name: "start_time",
          type: FIELD_TYPES.STATIC_TIME_PICKER,
          label: "Change UTC time of interval start",
          defaultValue: dayjs(workloadDetails?.start_time, "hh:mm A"),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        },
        // {
        //   name: "timezone",
        //   type: FIELD_TYPES.SELECT,
        //   label: "Time Zone",
        //   variant: "outlined",
        //   options: timeZones.map((timeZone) => ({
        //     label:
        //       timeZone === currentTimeZone ? `${timeZone} (Current)` : timeZone,
        //     value: timeZone
        //   })),
        //   defaultValue: {
        //     label: workloadDetails?.timezone,
        //     value: workloadDetails?.timezone
        //   },
        //   isHidden: (fieldValues) => {
        //     return !fieldValues.scheduler_enabled;
        //   }
        // },
        {
          name: "interval",
          type: FIELD_TYPES.NUMBER,
          defaultValue: workloadDetails?.schedule_interval
            ? workloadDetails?.schedule_interval.split(" ")[0]
            : 24,
          label: "Repeat every (in hrs)",
          suffix: "hrs",
          min: 1,
          rules: number().integer(),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        }
      ]
    },
    [DIALOG_TYPES.DELETE_WORKLOAD]: {
      isLocked: false,
      onConfirm: handleConfirmDeleteWorkload,
      title: `Are you sure you want to delete "${
        deletingWorkloadName ?? "selected"
      }" workload?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the workload name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Workload name does not match",
              test: function (value) {
                return value === deletingWorkloadName;
              }
            })
        },
        {
          name: "deletionInfo",
          type: FIELD_TYPES.NOTES,
          label: `\n\nℹ️ Please remove the all backups before proceeding with workload deletion.`
        }
      ],
      confirmButtonLabel: "Delete"
    }
  };

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.vms}`,
        action: instancesActions.getInstances.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.backups_workloads}`,
        action: backupsActions.getBackupsWorkloads.started({
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          region: matchParams.regionId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(instancesActions.clear());
    dispatch(backupsActions.clear());
  });

  useEffect(() => {
    if (
      previousIsWorkloadsOperationInProgress &&
      !isWorkloadsOperationInProgress
    ) {
      dispatch(
        backupsActions.getBackupsWorkloads.started({
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          region: matchParams.regionId!
        })
      );
    }
  }, [
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.organizationId,
    previousIsWorkloadsOperationInProgress,
    isWorkloadsOperationInProgress
  ]);

  return (
    <>
      <Head title={title} />
      {organization && project && <Breadcrumbs breadcrumbs={breadcrumbs} />}
      <Typography variant={"h4"} component={"h2"}>
        <Badge
          color="warning"
          badgeContent={
            <span style={{ paddingLeft: "5px", paddingRight: "5px" }}>
              beta
            </span>
          }
          style={{ paddingRight: "20px" }}
        >
          {title}
        </Badge>
      </Typography>
      <Table
        isSearchEnabled={true}
        isSortingEnabled={true}
        rows={workloads || []}
        columns={tableWorkloadsColumns}
        actions={tableActions}
        itemLink={{
          column: "name",
          getURL: generateTableItemURL
        }}
        isLoading={!workloads}
        // isLoading={areBackupsWorkloadsLoading}
        toolbarItems={
          <Button
            disabled={!virtualMachines}
            onClick={handleCreateWorkloadButtonClick}
            variant={"contained"}
          >
            Create Workload
          </Button>
        }
      />
      <FormDialog
        isOpened={dialog.isOpened}
        onCancel={handleCloseDialog}
        isLocked={dialogProps[dialog.type].isLocked}
        fields={dialogProps[dialog.type].fields}
        onConfirm={dialogProps[dialog.type].onConfirm}
        title={dialogProps[dialog.type].title}
        confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
        isLoading={dialogProps[dialog.type].isLoading}
        yupNoSortEdges={dialogProps[dialog.type].yupNoSortEdges}
      />
    </>
  );
};
