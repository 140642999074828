import { AxiosResponse } from "axios";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  CancelBackupsWorkloadSnapshotsParams,
  CancelBackupsWorkloadSnapshotsResponse,
  CancelRestoreParams,
  CancelRestoreResponse,
  CreateBackupsWorkloadParams,
  CreateBackupsWorkloadResponse,
  CreateBackupsWorkloadSnapshotsParams,
  CreateBackupsWorkloadSnapshotsResponse,
  CreateInPlaceRestoreParams,
  CreateInPlaceRestoreResponse,
  CreateSelectiveRestoreParams,
  CreateSelectiveRestoreResponse,
  DeleteBackupsWorkloadParams,
  DeleteBackupsWorkloadResponse,
  DeleteBackupsWorkloadSnapshotsParams,
  DeleteBackupsWorkloadSnapshotsResponse,
  DeleteRestoreParams,
  EditBackupsWorkloadParams,
  EditBackupsWorkloadResponse,
  GetBackupsWorkloadDetailsParams,
  GetBackupsWorkloadDetailsResponse,
  GetBackupsWorkloadSnapshotDetailsParams,
  GetBackupsWorkloadSnapshotDetailsResponse,
  GetBackupsWorkloadSnapshotsParams,
  GetBackupsWorkloadSnapshotsResponse,
  GetBackupsWorkloadsParams,
  GetBackupsWorkloadsResponse,
  GetRestoresParams,
  GetRestoresResponse,
  MultiDeleteBackupsWorkloadSnapshotsParams,
  MultiDeleteBackupsWorkloadSnapshotsResponse
} from "./types";

export function* getBackupsWorkloadsSaga(
  action: Action<GetBackupsWorkloadsParams>
): SagaIterator<void> {
  try {
    const { orgId, projectId, region } = action.payload;
    const response: AxiosResponse<GetBackupsWorkloadsResponse> = yield call(
      axiosInstance.get,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getBackupsWorkloads.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getBackupsWorkloads.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get backups workloads data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getBackupsWorkloadDetailsSaga(
  action: Action<GetBackupsWorkloadDetailsParams>
): SagaIterator<void> {
  try {
    const { orgId, projectId, region, workloadId } = action.payload;
    const response: AxiosResponse<GetBackupsWorkloadDetailsResponse> =
      yield call(
        axiosInstance.get,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}`
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getBackupsWorkloadDetails.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getBackupsWorkloadDetails.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get backup details",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createBackupsWorkloadSaga(
  action: Action<CreateBackupsWorkloadParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the backups workload...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateBackupsWorkloadResponse> = yield call(
      axiosInstance.post,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads`,
      data
    );
    yield put(
      actions.createBackupsWorkload.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Backups workload has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createBackupsWorkload.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create backups workload",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* editBackupsWorkloadSaga(
  action: Action<EditBackupsWorkloadParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Editing the backups workload...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, id, data } = action.payload;
    const response: AxiosResponse<EditBackupsWorkloadResponse> = yield call(
      axiosInstance.put,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${id}`,
      data
    );
    yield put(
      actions.editBackupsWorkload.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Backups workload has been successfully edited.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.editBackupsWorkload.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to edit backups workload",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteBackupsWorkloadSaga(
  action: Action<DeleteBackupsWorkloadParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete backups workload...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteBackupsWorkloadResponse> = yield call(
      axiosInstance.delete,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${id}`
    );
    yield put(
      actions.deleteBackupsWorkload.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete backups workload has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteBackupsWorkload.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete backups workload",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// snapshots
export function* getBackupsWorkloadSnapshotsSaga(
  action: Action<GetBackupsWorkloadSnapshotsParams>
): SagaIterator<void> {
  try {
    const { orgId, projectId, region, workloadId } = action.payload;
    const response: AxiosResponse<GetBackupsWorkloadSnapshotsResponse> =
      yield call(
        axiosInstance.get,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}/snapshots`
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getBackupsWorkloadSnapshots.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getBackupsWorkloadSnapshots.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get workload backups data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createBackupsWorkloadSnapshotsSaga(
  action: Action<CreateBackupsWorkloadSnapshotsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the backup...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, workloadId, data } = action.payload;
    const response: AxiosResponse<CreateBackupsWorkloadSnapshotsResponse> =
      yield call(
        axiosInstance.post,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}/snapshots`,
        data
      );
    yield put(
      actions.createBackupsWorkloadSnapshot.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Backup has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createBackupsWorkloadSnapshot.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create backup",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteBackupsWorkloadSnapshotsSaga(
  action: Action<DeleteBackupsWorkloadSnapshotsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete backup...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, workloadId, id } = action.payload;
    const response: AxiosResponse<DeleteBackupsWorkloadSnapshotsResponse> =
      yield call(
        axiosInstance.delete,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}/snapshots/${id}`
      );
    yield put(
      actions.deleteBackupsWorkloadSnapshot.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete backup has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteBackupsWorkloadSnapshot.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete backup",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* multiDeleteBackupsWorkloadSnapshotsSaga(
  action: Action<MultiDeleteBackupsWorkloadSnapshotsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete backups...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, workloadId, snapshot_ids } =
      action.payload;
    const response: AxiosResponse<MultiDeleteBackupsWorkloadSnapshotsResponse> =
      yield call(
        axiosInstance.post,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}/snapshots/multi-delete`,
        { snapshot_ids }
      );

    const entries = Object.entries(response.data);

    const formattedEntries = entries.map(
      ([snapshotId, Message]: [string, string]) => {
        const MessageWithoutDetails = Message.replace(/\. .*$/, "");
        return `${snapshotId} - ${MessageWithoutDetails}`;
      }
    );

    const { deletedSnapshots, failedToDeleteSnapshots } =
      formattedEntries.reduce(
        (result, entry) => {
          if (entry.includes("deleted")) {
            result.deletedSnapshots.push(entry);
          } else {
            result.failedToDeleteSnapshots.push(entry);
          }
          return result;
        },
        {
          deletedSnapshots: [] as string[],
          failedToDeleteSnapshots: [] as string[]
        }
      );

    const resultText = `${deletedSnapshots.join("\n")}\n${
      failedToDeleteSnapshots.length > 0
        ? `${failedToDeleteSnapshots.join(
            "\n"
          )}\n\nNotice: You can delete backups that are in 'available', 'error' or 'cancelled' status`
        : ""
    }`;

    yield put(
      actions.multiDeleteBackupsWorkloadSnapshots.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: `Open to see results...`,
        text: `${resultText}`,
        type: NOTIFICATION_TYPES.INFO
      })
    );
  } catch (e) {
    yield put(
      actions.multiDeleteBackupsWorkloadSnapshots.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete backups",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* cancelBackupsWorkloadSnapshotsSaga(
  action: Action<CancelBackupsWorkloadSnapshotsParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to cancel backup...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, workloadId, id } = action.payload;
    const response: AxiosResponse<CancelBackupsWorkloadSnapshotsResponse> =
      yield call(
        axiosInstance.get,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}/snapshots/${id}/cancel`
      );
    yield put(
      actions.cancelBackupsWorkloadSnapshot.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to cancel backup has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.cancelBackupsWorkloadSnapshot.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to cancel backup",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getBackupsWorkloadSnapshotDetailsSaga(
  action: Action<GetBackupsWorkloadSnapshotDetailsParams>
): SagaIterator<void> {
  try {
    const { orgId, projectId, region, workloadId, snapshotId } = action.payload;
    const response: AxiosResponse<GetBackupsWorkloadSnapshotDetailsResponse> =
      yield call(
        axiosInstance.get,
        `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/workloads/${workloadId}/snapshots/${snapshotId}`
      );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getBackupsWorkloadSnapshotDetails.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getBackupsWorkloadSnapshotDetails.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get backup details",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

// restores
export function* getRestoresSaga(
  action: Action<GetRestoresParams>
): SagaIterator<void> {
  try {
    const { orgId, projectId, region, workloadId, snapshotId } = action.payload;
    const response: AxiosResponse<GetRestoresResponse> = yield call(
      axiosInstance.get,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/snapshots/${snapshotId}/restores`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getRestores.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getRestores.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get restores data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createSelectiveRestoreSaga(
  action: Action<CreateSelectiveRestoreParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to create restore...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, snapshotId, data } = action.payload;
    const response: AxiosResponse<CreateSelectiveRestoreResponse> = yield call(
      axiosInstance.post,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/snapshots/${snapshotId}/selective-restore`,
      data
    );
    yield put(
      actions.createSelectiveRestore.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to create restore has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createSelectiveRestore.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to create restore",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createInPlaceRestoreSaga(
  action: Action<CreateInPlaceRestoreParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to create inplace restore...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, snapshotId, data } = action.payload;
    const response: AxiosResponse<CreateInPlaceRestoreResponse> = yield call(
      axiosInstance.post,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/snapshots/${snapshotId}/inplace-restore`,
      data
    );
    yield put(
      actions.createInPlaceRestore.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to create inplace restore has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createInPlaceRestore.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to create inplace restore",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteRestoreSaga(
  action: Action<DeleteRestoreParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete restore...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, workloadId, restoreId } = action.payload;
    const response: AxiosResponse<DeleteBackupsWorkloadResponse> = yield call(
      axiosInstance.delete,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/restores/${restoreId}`
    );
    yield put(
      actions.deleteRestore.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete restore has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteRestore.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete restore",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* cancelRestoreSaga(
  action: Action<CancelRestoreParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to cancel restore...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { region, orgId, projectId, workloadId, restoreId } = action.payload;
    const response: AxiosResponse<CancelRestoreResponse> = yield call(
      axiosInstance.get,
      `gotham-${region}-guardian/method/orgs/${orgId}/regions/${region}/projects/${projectId}/restores/${restoreId}/cancel`
    );
    yield put(
      actions.cancelRestore.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to cancel restore has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.cancelRestore.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to cancel restore",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getBackupsWorkloads.started, getBackupsWorkloadsSaga),
    takeEvery(actions.createBackupsWorkload.started, createBackupsWorkloadSaga),
    takeEvery(actions.editBackupsWorkload.started, editBackupsWorkloadSaga),
    takeEvery(actions.deleteBackupsWorkload.started, deleteBackupsWorkloadSaga),
    takeLeading(
      actions.getBackupsWorkloadDetails.started,
      getBackupsWorkloadDetailsSaga
    ),
    takeLeading(
      actions.getBackupsWorkloadSnapshots.started,
      getBackupsWorkloadSnapshotsSaga
    ),
    takeEvery(
      actions.createBackupsWorkloadSnapshot.started,
      createBackupsWorkloadSnapshotsSaga
    ),
    takeEvery(
      actions.cancelBackupsWorkloadSnapshot.started,
      cancelBackupsWorkloadSnapshotsSaga
    ),
    takeEvery(
      actions.deleteBackupsWorkloadSnapshot.started,
      deleteBackupsWorkloadSnapshotsSaga
    ),
    takeEvery(
      actions.multiDeleteBackupsWorkloadSnapshots.started,
      multiDeleteBackupsWorkloadSnapshotsSaga
    ),
    takeLeading(
      actions.getBackupsWorkloadSnapshotDetails.started,
      getBackupsWorkloadSnapshotDetailsSaga
    ),
    takeLeading(actions.getRestores.started, getRestoresSaga),
    takeEvery(
      actions.createSelectiveRestore.started,
      createSelectiveRestoreSaga
    ),
    takeEvery(actions.createInPlaceRestore.started, createInPlaceRestoreSaga),
    takeEvery(actions.cancelRestore.started, cancelRestoreSaga),
    takeEvery(actions.deleteRestore.started, deleteRestoreSaga)
  ]);
}
