export enum TABS {
  VMS,
  RESTORES
}

export enum DIALOG_TYPES {
  DELETE_SNAPSHOT,
  CANCEL_SNAPSHOT,
  CREATE_RESTORE,
  CANCEL_RESTORE,
  DELETE_RESTORE
}
