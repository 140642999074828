import { SelectChangeEvent } from "@mui/material";
import { ChangeEvent, ReactNode } from "react";

export enum TABLE_SORTING_TYPES {
  STRING,
  NUMBER,
  DATE
}

export type TableColumn<T> = {
  key: keyof T;
  label: string;
  width?: string;
  isCentered?: boolean;
  sortingType?: TABLE_SORTING_TYPES;
};

export type TableRowActionsMenuItem<T> = {
  label: string;
  handler: (id: string) => void;
  isDisabled?: (row: T) => boolean;
};

export type TableTitleActionsMenuItem = {
  label: string;
  handler: (id?: string) => void;
  isDisabled?: boolean;
  icon?: ReactNode;
  id?: string;
};

export type TableMultiActionsItem<T> = {
  label: string;
  handler: (ids: string[]) => void;
  isDisabled?: (row: T) => boolean;
  icon: ReactNode;
};

export type TableProps<T> = {
  title?: string;
  titleDetails?: ReactNode;
  disabledRows?: T[];
  rows: T[];
  columns: TableColumn<T>[];
  totalRow?: { [key in keyof T]: string };
  actions?: TableRowActionsMenuItem<T>[];
  titleActions?: TableTitleActionsMenuItem[] | null;
  isSelectingEnabled?: boolean;
  isRowSelectable?: (row: T) => boolean;
  multiActions?: TableMultiActionsItem<T>[];
  tabs?: ReactNode;
  toolbarItems?: ReactNode;
  isLoading?: boolean;
  itemLink?: {
    column: keyof T;
    getURL: (id: string) => string | undefined;
    isEnabled?: (item: T) => boolean;
  };
  // itemWithIcon?: {
  //   column: keyof T;
  //   rowSpecificity: string;
  //   tooltipText: string;
  //   icon: React.ReactElement;
  // };
  itemWithIcon?: {
    withText: boolean;
    column: keyof T;
    getIcon: (row: T, columnKey: keyof T) => React.ReactElement;
    tooltipText: (row: T) => string;
  };
  isSearchEnabled?: boolean;
  isSortingEnabled?: boolean;
  isPaginationEnabled?: boolean;
  isAdvancedPaginationEnabled?: boolean | null;
  page?: number;
  rowsPerPage?: number;
  count?: number;
  isNextPageAvailable?: boolean;
  // onChangePage?: (event:  MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
  onChangePage?: (event: any, newPage: number) => void;
  onChangeRowsPerPage?: (
    event:
      | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<number>
  ) => void;
  searchString?: string;
  onChangeSearch?: (e: ChangeEvent<HTMLInputElement>) => void;
  resetCheckboxes?: boolean;
  onResetCheckboxes?: () => void;
  onSelectRows?: (selectedRows: string[]) => void;
};

export enum ORDERS {
  ASC = "asc",
  DESC = "desc"
}

type IconButtonColor =
  | "inherit"
  | "default"
  | "info"
  | "error"
  | "primary"
  | "secondary"
  | "success"
  | "warning";

export type TableMenuItem = {
  title: string;
  icon: JSX.Element;
  color: IconButtonColor;
  onClick?: () => void;
  disabled?: boolean;
};
