import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatTime = (seconds: number) => {
  if (seconds < 60) {
    return `${seconds} sec`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} min`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hr ${minutes} min`;
  } else {
    const days = Math.floor(seconds / 86400);
    const hours = Math.floor((seconds % 86400) / 3600);
    const minutes = Math.floor(((seconds % 86400) % 3600) / 60);
    return `${days} day ${hours} hr ${minutes} min`;
  }
};

export const roundToNearest15Minutes = (time: dayjs.Dayjs): dayjs.Dayjs => {
  const roundedMinutes = Math.ceil(time.minute() / 15) * 15;
  return time.minute(roundedMinutes).second(0);
};

export const convertTimeToTimeZone = (
  time: string,
  timezone: string
): string => {
  return dayjs.utc(time, "h:mm A").tz(timezone).format("h:mm A");
};
