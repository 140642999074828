import { AxiosResponse } from "axios";
import { apiReloginSaga } from "modules/auth/sagas";
import * as notificationsActions from "modules/notifications/actions";
import { NOTIFICATION_TYPES } from "modules/notifications/types";
import { isPollStoppedSaga } from "modules/polling/sagas";
import { SagaIterator } from "redux-saga";
import { all, call, put, takeEvery, takeLeading } from "redux-saga/effects";
import { Action } from "typescript-fsa";
import { getAxiosErrorMessage } from "utils/getAxiosErrorMessage";
import { axiosInstance } from "../../axios";
import * as actions from "./actions";
import {
  AssignGroupToProjectParams,
  CreateCLIUserParams,
  CreateCLIUserResponse,
  CreateProjectParams,
  CreateProjectResponse,
  DeleteCLIUserParams,
  DeleteCLIUserResponse,
  DeleteProjectParams,
  GetCLIUserParams,
  GetCLIUserResponse,
  GetCLIUsersParams,
  GetCLIUsersResponse,
  GetOrganizationProjectsParams,
  GetOrganizationProjectsResponse,
  GetProjectParams,
  GetProjectResponse,
  RemoveGroupFromProjectParams,
  UpdateCLIUserParams,
  UpdateCLIUserResponse,
  UpdateProjectParams,
  UpdateProjectResponse
} from "./types";

export function* getProjectSaga(
  action: Action<GetProjectParams>
): SagaIterator<void> {
  try {
    const { regionId, id } = action.payload;
    const response: AxiosResponse<GetProjectResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-identity/method/projects/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getProject.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getProject.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get project data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getOrganizationProjectsSaga(
  action: Action<GetOrganizationProjectsParams>
): SagaIterator<void> {
  try {
    const response: AxiosResponse<GetOrganizationProjectsResponse> = yield call(
      axiosInstance.get,
      `gotham-enterprises/method/orgs/${action.payload.organizationId}/projects`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getOrganizationProjects.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(
      actions.getOrganizationProjects.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get organization projects data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createProjectSaga(
  action: Action<CreateProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, organizationId, data } = action.payload;
    const response: AxiosResponse<CreateProjectResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-identity/method/${organizationId}/projects/${data.name}`
    );

    // Start new session to gain access to the created project
    yield* apiReloginSaga();
    yield put(
      actions.createProject.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Project has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateProjectSaga(
  action: Action<UpdateProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, organizationId, id, data } = action.payload;
    const response: AxiosResponse<UpdateProjectResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-identity/method/${organizationId}/projects/${id}`,
      data
    );
    yield put(
      actions.updateProject.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Project has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteProjectSaga(
  action: Action<DeleteProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Deleting the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, organizationId, id } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-identity/method/${organizationId}/projects/${id}`
    );
    yield put(
      actions.deleteProject.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Project has been successfully deleted.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to delete project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getCLIUserSaga(
  action: Action<GetCLIUserParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<GetCLIUserResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-identity/method/${projectId}/cli-users/${id}`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getCLIUser.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getCLIUser.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get CLI user data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* getCLIUsersSaga(
  action: Action<GetCLIUsersParams>
): SagaIterator<void> {
  try {
    const { regionId, projectId } = action.payload;
    const response: AxiosResponse<GetCLIUsersResponse> = yield call(
      axiosInstance.get,
      `gotham-${regionId}-identity/method/${projectId}/cli-users`
    );
    const isPollStopped: boolean = yield call(isPollStoppedSaga, action);
    if (!isPollStopped) {
      yield put(
        actions.getCLIUsers.done({
          params: action.payload,
          result: response.data
        })
      );
    }
  } catch (e) {
    yield put(actions.getCLIUsers.failed({ params: action.payload, error: e }));
    yield put(
      notificationsActions.showNotification({
        title: "Failed to get CLI users data",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* createCLIUserSaga(
  action: Action<CreateCLIUserParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Creating the CLI user...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, data } = action.payload;
    const response: AxiosResponse<CreateCLIUserResponse> = yield call(
      axiosInstance.post,
      `gotham-${regionId}-identity/method/${projectId}/cli-users`,
      data
    );
    yield put(
      actions.createCLIUser.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "CLI user has been successfully created.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.createCLIUser.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to create CLI user",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* updateCLIUserSaga(
  action: Action<UpdateCLIUserParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Updating the CLI user...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id, data } = action.payload;
    const response: AxiosResponse<UpdateCLIUserResponse> = yield call(
      axiosInstance.put,
      `gotham-${regionId}-identity/method/${projectId}/cli-users/${id}`,
      data
    );
    yield put(
      actions.updateCLIUser.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "CLI user has been successfully updated.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.updateCLIUser.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to update CLI user",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* deleteCLIUserSaga(
  action: Action<DeleteCLIUserParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Sending request to delete CLI user...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, projectId, id } = action.payload;
    const response: AxiosResponse<DeleteCLIUserResponse> = yield call(
      axiosInstance.delete,
      `gotham-${regionId}-identity/method/${projectId}/cli-users/${id}`
    );
    yield put(
      actions.deleteCLIUser.done({
        params: action.payload,
        result: response.data
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Request to delete CLI user has been successfully sent.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.deleteCLIUser.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to send request to delete CLI user",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* assignGroupToProjectSaga(
  action: Action<AssignGroupToProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Assigning group to the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, organizationId, groupId, projectId } = action.payload;
    yield call(
      axiosInstance.post,
      `gotham-${regionId}-identity/method/${organizationId}/projects/${projectId}/groups/${groupId}`
    );
    yield put(
      actions.assignGroupToProject.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully assigned to the project.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.assignGroupToProject.failed({ params: action.payload, error: e })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to assign group to the project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* removeGroupFromProjectSaga(
  action: Action<RemoveGroupFromProjectParams>
): SagaIterator<void> {
  try {
    yield put(
      notificationsActions.showNotification({
        title: "Removing group from the project...",
        type: NOTIFICATION_TYPES.PROGRESS
      })
    );
    const { regionId, organizationId, groupId, projectId } = action.payload;
    yield call(
      axiosInstance.delete,
      `gotham-${regionId}-identity/method/${organizationId}/projects/${projectId}/groups/${groupId}`
    );
    yield put(
      actions.removeGroupFromProject.done({
        params: action.payload
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Group has been successfully removed from the project.",
        type: NOTIFICATION_TYPES.SUCCESS
      })
    );
  } catch (e) {
    yield put(
      actions.removeGroupFromProject.failed({
        params: action.payload,
        error: e
      })
    );
    yield put(
      notificationsActions.showNotification({
        title: "Failed to remove group from the project",
        text: getAxiosErrorMessage(e),
        type: NOTIFICATION_TYPES.ERROR
      })
    );
  }
}

export function* watcherSaga(): SagaIterator<void> {
  yield all([
    takeLeading(actions.getProject.started, getProjectSaga),
    takeLeading(
      actions.getOrganizationProjects.started,
      getOrganizationProjectsSaga
    ),
    takeEvery(actions.createProject.started, createProjectSaga),
    takeEvery(actions.updateProject.started, updateProjectSaga),
    takeEvery(actions.deleteProject.started, deleteProjectSaga),
    takeLeading(actions.getCLIUser.started, getCLIUserSaga),
    takeLeading(actions.getCLIUsers.started, getCLIUsersSaga),
    takeEvery(actions.createCLIUser.started, createCLIUserSaga),
    takeEvery(actions.updateCLIUser.started, updateCLIUserSaga),
    takeEvery(actions.deleteCLIUser.started, deleteCLIUserSaga),
    takeEvery(actions.assignGroupToProject.started, assignGroupToProjectSaga),
    takeEvery(
      actions.removeGroupFromProject.started,
      removeGroupFromProjectSaga
    )
  ]);
}
