import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./actions";
import {
  BackupsWorkloadSnapshot,
  BackupsWorkload,
  BackupsWorkloadDetails,
  BackupsWorkloads,
  BackupsWorkloadSnapshotDetails,
  RestoreFromList
} from "./types";
import { isNullishCoalesce } from "typescript";

export type Reducer = {
  backupsWorkloads: BackupsWorkload[] | null;
  areBackupsWorkloadsLoading: boolean;
  backupsWorkloadDetails: BackupsWorkloadDetails | null;
  isBackupsWorkloadDetailsLoading: boolean;
  isBackupsWorkloadCreating: boolean;
  isBackupsWorkloadEditing: boolean;
  isBackupsWorkloadDeleting: boolean;
  backupsSnapshots: BackupsWorkloadSnapshot[] | null;
  areBackupsSnapshotsLoading: boolean;
  isBackupsWorkloadSnapshotDeleting: boolean;
  areBackupsWorkloadSnapshotsMultiDeleting: boolean;
  isBackupsWorkloadSnapshotCreating: boolean;
  isBackupsWorkloadSnapshotCanceling: boolean;
  backupsSnapshotDetails: BackupsWorkloadSnapshotDetails | null;
  isBackupsSnapshotDetailsLoading: boolean;
  restores: RestoreFromList[] | null;
  areRestoresLoading: boolean;
  isSelectiveRestoreCreating: boolean;
  isInPlaceRestoreCreating: boolean;
  isRestoreDeleting: boolean;
  isRestoreCanceling: boolean;
};

const initialState: Reducer = {
  backupsWorkloads: null,
  areBackupsWorkloadsLoading: false,
  backupsWorkloadDetails: null,
  isBackupsWorkloadDetailsLoading: false,
  isBackupsWorkloadCreating: false,
  isBackupsWorkloadEditing: false,
  isBackupsWorkloadDeleting: false,
  backupsSnapshots: null,
  areBackupsSnapshotsLoading: false,
  isBackupsWorkloadSnapshotCreating: false,
  isBackupsWorkloadSnapshotDeleting: false,
  areBackupsWorkloadSnapshotsMultiDeleting: false,
  isBackupsWorkloadSnapshotCanceling: false,
  backupsSnapshotDetails: null,
  isBackupsSnapshotDetailsLoading: false,
  restores: null,
  areRestoresLoading: false,
  isSelectiveRestoreCreating: false,
  isInPlaceRestoreCreating: false,
  isRestoreDeleting: false,
  isRestoreCanceling: false
};

export const reducer = reducerWithInitialState(initialState)
  //get backups workloads
  .case(
    actions.getBackupsWorkloads.started,
    (state): Reducer => ({
      ...state,
      areBackupsWorkloadsLoading: true
    })
  )
  .case(
    actions.getBackupsWorkloads.done,
    (state, payload): Reducer => ({
      ...state,
      backupsWorkloads: payload.result,
      areBackupsWorkloadsLoading: false
    })
  )
  .case(
    actions.getBackupsWorkloads.failed,
    (state): Reducer => ({
      ...state,
      areBackupsWorkloadsLoading: false
    })
  )

  //details backups workload
  .case(
    actions.getBackupsWorkloadDetails.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadDetailsLoading: true
    })
  )
  .case(
    actions.getBackupsWorkloadDetails.done,
    (state, payload): Reducer => ({
      ...state,
      backupsWorkloadDetails: payload.result.workload,
      isBackupsWorkloadDetailsLoading: false
    })
  )
  .case(
    actions.getBackupsWorkloadDetails.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadDetailsLoading: false
    })
  )

  //create backups workload
  .case(
    actions.createBackupsWorkload.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadCreating: true
    })
  )
  .case(
    actions.createBackupsWorkload.done,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadCreating: false
    })
  )
  .case(
    actions.createBackupsWorkload.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadCreating: false
    })
  )

  //edit backups workloads
  .case(
    actions.editBackupsWorkload.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadEditing: true
    })
  )
  .case(
    actions.editBackupsWorkload.done,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadEditing: false
    })
  )
  .case(
    actions.editBackupsWorkload.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadEditing: false
    })
  )

  //delete backups workloads
  .case(
    actions.deleteBackupsWorkload.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadDeleting: true
    })
  )
  .case(
    actions.deleteBackupsWorkload.done,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadDeleting: false,
      backupsWorkloadDetails: null
    })
  )
  .case(
    actions.deleteBackupsWorkload.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadDeleting: false
    })
  )

  //get workload snapshots
  .case(
    actions.getBackupsWorkloadSnapshots.started,
    (state): Reducer => ({
      ...state,
      areBackupsSnapshotsLoading: true
    })
  )
  .case(
    actions.getBackupsWorkloadSnapshots.done,
    (state, payload): Reducer => ({
      ...state,
      backupsSnapshots: payload.result,
      areBackupsSnapshotsLoading: false
    })
  )
  .case(
    actions.getBackupsWorkloadSnapshots.failed,
    (state): Reducer => ({
      ...state,
      areBackupsSnapshotsLoading: false
    })
  )

  //details workload snapshot
  .case(
    actions.getBackupsWorkloadSnapshotDetails.started,
    (state): Reducer => ({
      ...state,
      isBackupsSnapshotDetailsLoading: true
    })
  )
  .case(
    actions.getBackupsWorkloadSnapshotDetails.done,
    (state, payload): Reducer => ({
      ...state,
      backupsSnapshotDetails: payload.result.snapshot,
      isBackupsSnapshotDetailsLoading: false
    })
  )
  .case(
    actions.getBackupsWorkloadSnapshotDetails.failed,
    (state): Reducer => ({
      ...state,
      isBackupsSnapshotDetailsLoading: false
    })
  )

  //create workload snapshot
  .case(
    actions.createBackupsWorkloadSnapshot.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotCreating: true
    })
  )
  .case(
    actions.createBackupsWorkloadSnapshot.done,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotCreating: false
    })
  )
  .case(
    actions.createBackupsWorkloadSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotCreating: false
    })
  )

  //multi-delete workload snapshot
  .case(
    actions.multiDeleteBackupsWorkloadSnapshots.started,
    (state): Reducer => ({
      ...state,
      areBackupsWorkloadSnapshotsMultiDeleting: true
    })
  )
  .case(
    actions.multiDeleteBackupsWorkloadSnapshots.done,
    (state): Reducer => ({
      ...state,
      // backupsSnapshotDetails: null,
      areBackupsWorkloadSnapshotsMultiDeleting: false
    })
  )
  .case(
    actions.multiDeleteBackupsWorkloadSnapshots.failed,
    (state): Reducer => ({
      ...state,
      areBackupsWorkloadSnapshotsMultiDeleting: false
    })
  )

  //delete workload snapshot
  .case(
    actions.deleteBackupsWorkloadSnapshot.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotDeleting: true
    })
  )
  .case(
    actions.deleteBackupsWorkloadSnapshot.done,
    (state): Reducer => ({
      ...state,
      backupsSnapshotDetails: null,
      isBackupsWorkloadSnapshotDeleting: false
    })
  )
  .case(
    actions.deleteBackupsWorkloadSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotDeleting: false
    })
  )

  //cancel workload snapshot
  .case(
    actions.cancelBackupsWorkloadSnapshot.started,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotCanceling: true
    })
  )
  .case(
    actions.cancelBackupsWorkloadSnapshot.done,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotCanceling: false
    })
  )
  .case(
    actions.cancelBackupsWorkloadSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isBackupsWorkloadSnapshotCanceling: false
    })
  )

  //get restores
  .case(
    actions.getRestores.started,
    (state): Reducer => ({
      ...state,
      areRestoresLoading: true
    })
  )
  .case(
    actions.getRestores.done,
    (state, payload): Reducer => ({
      ...state,
      // restores: payload.result.restores,
      restores: payload.result,
      areRestoresLoading: false
    })
  )
  .case(
    actions.getRestores.failed,
    (state): Reducer => ({
      ...state,
      areRestoresLoading: false
    })
  )

  //create selective restore
  .case(
    actions.createSelectiveRestore.started,
    (state): Reducer => ({
      ...state,
      isSelectiveRestoreCreating: true
    })
  )
  .case(
    actions.createSelectiveRestore.done,
    (state): Reducer => ({
      ...state,
      isSelectiveRestoreCreating: false
    })
  )
  .case(
    actions.createSelectiveRestore.failed,
    (state): Reducer => ({
      ...state,
      isSelectiveRestoreCreating: false
    })
  )
  // inPlace restore
  .case(
    actions.createInPlaceRestore.started,
    (state): Reducer => ({
      ...state,
      isInPlaceRestoreCreating: true
    })
  )
  .case(
    actions.createInPlaceRestore.done,
    (state): Reducer => ({
      ...state,
      isInPlaceRestoreCreating: false
    })
  )
  .case(
    actions.createInPlaceRestore.failed,
    (state): Reducer => ({
      ...state,
      isInPlaceRestoreCreating: false
    })
  )

  //delete restore
  .case(
    actions.deleteRestore.started,
    (state): Reducer => ({
      ...state,
      isRestoreDeleting: true
    })
  )
  .case(
    actions.deleteRestore.done,
    (state): Reducer => ({
      ...state,
      isRestoreDeleting: false
    })
  )
  .case(
    actions.deleteRestore.failed,
    (state): Reducer => ({
      ...state,
      isRestoreDeleting: false
    })
  )

  //cancel workload snapshot
  .case(
    actions.cancelRestore.started,
    (state): Reducer => ({
      ...state,
      isRestoreCanceling: true
    })
  )
  .case(
    actions.cancelBackupsWorkloadSnapshot.done,
    (state): Reducer => ({
      ...state,
      isRestoreCanceling: false
    })
  )
  .case(
    actions.cancelBackupsWorkloadSnapshot.failed,
    (state): Reducer => ({
      ...state,
      isRestoreCanceling: false
    })
  )

  //clear
  .case(
    actions.clearBackupsWorkloads,
    (state): Reducer => ({
      ...state,
      backupsWorkloads: initialState.backupsWorkloads
    })
  )

  .case(
    actions.clearWorkloadDetails,
    (state): Reducer => ({
      ...state,
      backupsWorkloadDetails: initialState.backupsWorkloadDetails
    })
  )

  .case(
    actions.clearBackupsSnapshots,
    (state): Reducer => ({
      ...state,
      backupsSnapshots: initialState.backupsSnapshots
    })
  )
  .case(actions.clear, (): Reducer => initialState);
