import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import { Button, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableMenuItem,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";
import {
  instancesSelector,
  selectOptionFlavorsSelector
} from "modules/instances/selectors";

import { TAB_TITLES as WORKLOAD_TAB_TITLES } from "components/BackupsWorkload";
import { TABS as WORKLOAD_TABS } from "components/BackupsWorkload/types";
import * as backupsActions from "modules/backups/actions";
import {
  areRestoreLoadingSelector,
  backupsSnapshotDetailsSelector,
  backupsWorkloadDetailsSelector,
  isBackupsSnapshotDetailsLoadingSelector,
  isBackupsWorkloadSnapshotCancelingSelector,
  isBackupsWorkloadSnapshotDeletingSelector,
  isInPlaceRestoreCreatingSelector,
  isRestoreCancelingSelector,
  isRestoreDeletingSelector,
  isSelectiveRestoreCreatingSelector,
  restoresSelector,
  tableBackupsSnapshotDetailsAssignedVMsSelector,
  tableBackupsSnapshotDetailsSelector,
  tableRestoresSelector
} from "modules/backups/selectors";
import {
  TableBackupsWorkloadSnapshotDetailsAssignedVMs,
  TableRestore
} from "modules/backups/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { generateSearchString } from "utils/generateSearchString";
import { getSelectOption } from "utils/getSelectOption";
import { object, string } from "yup";
import { ERROR_MESSAGES, ROUTES } from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";
import { FLAVOR_TYPE } from "modules/instances/types";

const POLL_ID_PREFIX = "BACKUP_WORKLOAD";
const POLL_IDS = {
  workload: "WORKLOAD",
  snapshotInfo: "SNAPSHOT_INFO",
  restores: "RESTORES",
  vms: "VMS"
};

const restoresTableColumns: TableColumn<TableRestore>[] = [
  { key: "id", label: "ID" },
  { key: "name", label: "Name" },
  { key: "restore_type", label: "Type" },
  { key: "size", label: "Size", sortingType: TABLE_SORTING_TYPES.NUMBER },
  {
    key: "progress_percent",
    label: "Progress",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  },
  {
    key: "time_taken",
    label: "Time taken",
    sortingType: TABLE_SORTING_TYPES.NUMBER
  },
  { key: "status", label: "Status" },
  { key: "created_at", label: "Created", sortingType: TABLE_SORTING_TYPES.DATE }
];

const assignedVMsTableColumns: TableColumn<TableBackupsWorkloadSnapshotDetailsAssignedVMs>[] =
  [
    { key: "name", label: "Name" },
    { key: "id", label: "ID" },
    { key: "status", label: "VM Backup Status" }
  ];

export const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.VMS]: "Backuped VMs",
  [TABS.RESTORES]: "Restores"
};

export const BackupsSnapshot: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const matchParams = useParams<{
    organizationId: string;
    projectId: string;
    regionId: string;
    workloadId: string;
    snapshotId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const flavors = useSelector(selectOptionFlavorsSelector);
  const workloadDetails = useSelector(backupsWorkloadDetailsSelector);
  const virtualMachines = useSelector(instancesSelector);

  const snapshotDetails = useSelector(backupsSnapshotDetailsSelector);
  const tableSnapshotDetails = useSelector(tableBackupsSnapshotDetailsSelector);
  const assignedVMs = useSelector(
    tableBackupsSnapshotDetailsAssignedVMsSelector
  );
  const isBackupsSnapshotDetailsLoading = useSelector(
    isBackupsSnapshotDetailsLoadingSelector
  );
  const restores = useSelector(restoresSelector);
  const tableRestores = useSelector(tableRestoresSelector);
  const areRestoreLoading = useSelector(areRestoreLoadingSelector);

  const isBackupsWorkloadSnapshotCanceling = useSelector(
    isBackupsWorkloadSnapshotCancelingSelector
  );
  const isBackupsWorkloadSnapshotDeleting = useSelector(
    isBackupsWorkloadSnapshotDeletingSelector
  );
  const isSnapshotsOperationInProgress =
    isBackupsWorkloadSnapshotCanceling || isBackupsWorkloadSnapshotDeleting;
  const previousIsSnapshotsOperationInProgress = usePrevious(
    isSnapshotsOperationInProgress
  );

  const isSelectiveRestoreCreating = useSelector(
    isSelectiveRestoreCreatingSelector
  );
  const isInPlaceRestoreCreating = useSelector(
    isInPlaceRestoreCreatingSelector
  );
  const isRestoreCanceling = useSelector(isRestoreCancelingSelector);
  const isRestoreDeleting = useSelector(isRestoreDeletingSelector);
  const isRestoresOperationInProgress =
    isSelectiveRestoreCreating ||
    isInPlaceRestoreCreating ||
    isRestoreCanceling ||
    isRestoreDeleting;
  const previousIsRestoresOperationInProgress = usePrevious(
    isRestoresOperationInProgress
  );

  const title = `Backup: ${snapshotDetails?.name}`;
  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Backups Workloads",
      url: generatePath(ROUTES.BACKUPS_WORKLOADS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: workloadDetails?.name || "",
      url: generatePath(ROUTES.BACKUPS_WORKLOAD, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        workloadId: matchParams.workloadId
      })
    },
    {
      text: "Backups",
      url: `${generatePath(ROUTES.BACKUPS_WORKLOAD, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        workloadId: matchParams.workloadId
      })}?${generateSearchString({
        tab: WORKLOAD_TAB_TITLES[WORKLOAD_TABS.BACKUPS]
      })}`
    },
    {
      text: snapshotDetails?.name || "",
      url: generatePath(ROUTES.BACKUPS_SNAPSHOT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        workloadId: matchParams.workloadId,
        snapshotId: matchParams.snapshotId
      })
    }
  ];

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.VMS) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
  }, []);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.DELETE_SNAPSHOT, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    []
  );

  const headerMenuItems: TableMenuItem[] = [
    {
      title: "Cancel backup",
      icon: <BlockIcon />,
      color: "inherit",
      onClick: () => handleDialogOpen(DIALOG_TYPES.CANCEL_SNAPSHOT)
    },
    {
      title: "Delete backup",
      icon: <DeleteIcon />,
      color: "inherit",
      onClick: () => handleDialogOpen(DIALOG_TYPES.DELETE_SNAPSHOT)
    }
  ];

  const restoresTableActions: TableRowActionsMenuItem<TableRestore>[] = [
    {
      label: "Cancel restore",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.CANCEL_RESTORE, id)
    },
    {
      label: "Delete restore",
      handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE_RESTORE, id)
    }
  ];

  const handleConfirmCancelBackupsWorkloadSnapshot = useCallback(() => {
    dispatch(
      backupsActions.cancelBackupsWorkloadSnapshot.started({
        region: matchParams.regionId!,
        orgId: matchParams.organizationId!,
        projectId: matchParams.projectId!,
        workloadId: matchParams.workloadId!,
        id: matchParams.snapshotId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.workloadId,
    matchParams.snapshotId,
    handleCloseDialog
  ]);

  const handleConfirmDeleteBackupsWorkloadSnapshot = useCallback(() => {
    dispatch(
      backupsActions.deleteBackupsWorkloadSnapshot.started({
        region: matchParams.regionId!,
        orgId: matchParams.organizationId!,
        projectId: matchParams.projectId!,
        workloadId: matchParams.workloadId!,
        id: matchParams.snapshotId!
      })
    );
    handleCloseDialog();
  }, [
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.workloadId,
    matchParams.snapshotId,
    handleCloseDialog
  ]);

  const handleConfirmCancelRestore = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        backupsActions.cancelRestore.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          restoreId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.workloadId
  ]);

  const handleConfirmDeleteRestore = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        backupsActions.deleteRestore.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          restoreId: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.workloadId
  ]);

  // use when will be opened inPlace Restore for clients
  // const handleConfirmRestore = useCallback(
  //   (data: {
  //     name: string;
  //     description?: string;
  //     instances?: SelectOption[];
  //     restore_type: string;
  //   }) => {
  //     if ((data.restore_type as RESTORE_TYPES) === RESTORE_TYPES.SELECTIVE) {
  //       dispatch(
  //         backupsActions.createSelectiveRestore.started({
  //           region: matchParams.regionId!,
  //           orgId: matchParams.organizationId!,
  //           projectId: matchParams.projectId!,
  //           workloadId: matchParams.workloadId!,
  //           snapshotId: matchParams.snapshotId!,
  //           data: {
  //             name: data.name,
  //             description: data.description || "",
  //             instance_ids: data.instances?.map((instance) => instance.value)
  //           }
  //         })
  //       );
  //       handleCloseDialog();
  //     }

  //     if ((data.restore_type as RESTORE_TYPES) === RESTORE_TYPES.INPLACE) {
  //       dispatch(
  //         backupsActions.createInPlaceRestore.started({
  //           region: matchParams.regionId!,
  //           orgId: matchParams.organizationId!,
  //           projectId: matchParams.projectId!,
  //           workloadId: matchParams.workloadId!,
  //           snapshotId: matchParams.snapshotId!,
  //           data: {
  //             restore: {
  //               name: data.name,
  //               description: data.description || "",
  //               options: {
  //                 openstack: {
  //                   instances: (data.instances || []).map((instance) => {
  //                     return {
  //                       name: instance.label,
  //                       id: instance.value,
  //                       include: true,
  //                       restore_boot_disk: true,
  //                       availability_zone: "nova",
  //                       vdisks: []
  //                     };
  //                   })
  //                 }
  //               }
  //             }
  //           }
  //         })
  //       );
  //       handleCloseDialog();
  //     }
  //   },
  //   [
  //     dispatch,
  //     handleCloseDialog,
  //     matchParams.organizationId,
  //     matchParams.projectId,
  //     matchParams.regionId,
  //     matchParams.snapshotId,
  //     matchParams.workloadId
  //   ]
  // );

  const handleConfirmRestore = useCallback(
    (data: {
      name: string;
      // description?: string;
      // instances?: SelectOption[];
      [key: string]: any;
    }) => {
      const selectedInstances = (snapshotDetails?.instances ?? [])
        .filter((vm) => data[vm.id]) // Filter selected vms be checkbox
        .map((vm) => ({
          id: vm.id,
          instance_name: data[`name-${vm.id}`], // set new name,
          flavor_id: data[`flavor-${vm.id}`]
            ? (data[`flavor-${vm.id}`] as SelectOption).value
            : undefined
        }));

      dispatch(
        backupsActions.createSelectiveRestore.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          snapshotId: matchParams.snapshotId!,
          data: {
            name: data.name,
            description: data.description || "",
            instances: selectedInstances
            // instance_ids: data.instances?.map((instance) => instance.value)
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      matchParams.snapshotId,
      matchParams.workloadId,
      snapshotDetails?.instances
    ]
  );

  const generateVmsTableItemURL = useCallback(
    (id: string) => {
      const isDeleted: boolean = virtualMachines?.find((vm) => vm.id === id)
        ? false
        : true;
      if (!isDeleted) {
        const url = generatePath(ROUTES.VIRTUAL_MACHINE, {
          organizationId: matchParams.organizationId,
          regionId: matchParams.regionId,
          projectId: matchParams.projectId,
          virtualMachineId: id
        });
        return url;
      } else {
        return "";
      }
    },
    [
      virtualMachines,
      matchParams.organizationId,
      matchParams.regionId,
      matchParams.projectId
    ]
  );

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentRestoreName = restores?.find(
    (restore) => restore.id === currentItemId
  )?.name;

  const tabContent = [
    <>
      <Table<TableBackupsWorkloadSnapshotDetailsAssignedVMs>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!assignedVMs}
        key={"assignedVMsTable"}
        rows={assignedVMs || []}
        columns={assignedVMsTableColumns}
        itemLink={{
          column: "name",
          getURL: generateVmsTableItemURL
        }}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE_RESTORE)}
            variant={"contained"}
            // disabled={snapshotDetails?.status !== "available"}
          >
            Restore VM
          </Button>
        }
      />
    </>,
    <>
      <Table<TableRestore>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!tableRestores}
        key={"restoresTable"}
        rows={tableRestores || []}
        columns={restoresTableColumns}
        actions={restoresTableActions}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE_RESTORE)}
            variant={"contained"}
            disabled={!flavors}
            // disabled={snapshotDetails?.status !== "available"}
          >
            Restore VM
          </Button>
        }
        itemWithIcon={{
          withText: false,
          column: "status",
          getIcon: (row) => {
            if (row["status"] === "available")
              return (
                <s.TypographyColoredSuccess>
                  {row["status"]}
                </s.TypographyColoredSuccess>
              );
            if (row["status"] === "error")
              return (
                <s.TypographyColoredDanger>
                  {row["status"]}
                </s.TypographyColoredDanger>
              );
            return (
              <s.CustomTableCellText>{row["status"]}</s.CustomTableCellText>
            ); // or default icon
          },
          tooltipText: (row) => {
            if (row.status === "error") return `Error:${String(row.error_msg)}`;
            return "";
          }
        }}
      />
    </>
  ];

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CANCEL_SNAPSHOT]: {
      onConfirm: handleConfirmCancelBackupsWorkloadSnapshot,
      title: `Are you sure you want to terminate "${
        snapshotDetails?.name ?? "selected"
      }" backup?`,
      confirmButtonLabel: "Terminate"
    },
    [DIALOG_TYPES.DELETE_SNAPSHOT]: {
      onConfirm: handleConfirmDeleteBackupsWorkloadSnapshot,
      title: `Are you sure you want to delete "${
        snapshotDetails?.name ?? "selected"
      }" backup?`,
      fields: [
        {
          name: "deletionInfo",
          type: FIELD_TYPES.NOTES,
          label: `\nℹ️ Reminder: backup can be deleted only when it is in available, error, or canceled state;\n\nand the current workload is in available state.`
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CANCEL_RESTORE]: {
      onConfirm: handleConfirmCancelRestore,
      title: `Are you sure you want to terminate "${
        currentRestoreName ?? "selected"
      }" restore?`,
      confirmButtonLabel: "Terminate"
    },
    [DIALOG_TYPES.DELETE_RESTORE]: {
      onConfirm: handleConfirmDeleteRestore,
      title: `Are you sure you want to delete "${
        currentRestoreName ?? "selected"
      }" restore?`,
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CREATE_RESTORE]: {
      onConfirm: handleConfirmRestore,
      title: "Restore from backup",
      confirmButtonLabel: "Restore",
      fields: [
        // {
        //   name: "restore_type",
        //   type: FIELD_TYPES.TOGGLE_BUTTON,
        //   label: "Restore type",
        //   helperText: `Choose restore type:\nSelective - creates new VM;\nInPlace - restore current VM`,
        //   options: Object.keys(RESTORE_TYPES_LABELS).map((x) => ({
        //     label: RESTORE_TYPES_LABELS[x],
        //     value: x
        //   })),
        //   defaultValue: RESTORE_TYPES.SELECTIVE,
        //   rules: string()
        // },
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Restore name",
          rules: string().required().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME)
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Select instances to restore:"
        },
        ...(snapshotDetails?.instances ?? [])
          .map((vm) => [
            {
              name: vm.id,
              type: FIELD_TYPES.CHECKBOX,
              defaultValue: false,
              fullWidth: true,
              margin: "8px 0 0 0",
              label: `${vm.name}`
            },
            {
              name: `name-${vm.id}`,
              type: FIELD_TYPES.TEXT,
              defaultValue: `${vm.name}-restored`,
              label: `New instance name`,
              rules: string().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME),
              isHidden: (fieldValues: Record<string, any>) =>
                !fieldValues?.[`${vm.id}`]
            },
            {
              name: `flavor-${vm.id}`,
              type: FIELD_TYPES.SELECT,
              helperText:
                "Leaving this field empty will keep the flavor settings unchanged",
              label: `New instance flavor`,
              options: flavors?.map((flavor) =>
                getSelectOption(flavor, "name", "id")
              ),
              //   defaultValue: () =>
              //     default_flavor
              //       ? getSelectOption(default_flavor, "name", "id")
              //       : null,
              rules: object({
                label: string().nullable(),
                value: string().nullable()
              }).nullable(),
              isHidden: (fieldValues: Record<string, any>) =>
                !fieldValues?.[`${vm.id}`]
            }
          ])
          .flat(),
        // {
        //   name: "instances",
        //   type: FIELD_TYPES.MULTISELECT,
        //   label: "Instances",
        //   options: snapshotDetails?.instances.map((vm) =>
        //     getSelectOption(vm, "name", "id")
        //   ),
        //   defaultValue: snapshotDetails?.instances.map((vm) =>
        //     getSelectOption(vm, "name", "id")
        //   ),
        //   rules: array(selectOptionSchema)
        // },
        // {
        //   name: "instancesInfo",
        //   type: FIELD_TYPES.NOTES,
        //   isHidden: (fieldValues) => {
        //     return (fieldValues.instances as SelectOption[]).length !== 0;
        //   },
        //   label: "\n\n⚠️ At least one instance should be selected"
        // },
        {
          name: "restoreInfo",
          type: FIELD_TYPES.NOTES,
          label:
            "\n\nℹ️ Restore can only be initiated if current backup and workload are in available states."
        }
      ]
    }
  };

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      instancesActions.getFlavors.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        type: FLAVOR_TYPE.INSTANCE
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.workload}`,
        action: backupsActions.getBackupsWorkloadDetails.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.snapshotInfo}`,
        action: backupsActions.getBackupsWorkloadSnapshotDetails.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          snapshotId: matchParams.snapshotId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.vms}`,
        action: instancesActions.getInstances.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.restores}`,
        action: backupsActions.getRestores.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          snapshotId: matchParams.snapshotId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(backupsActions.clear());
  });

  useEffect(() => {
    if (
      previousIsSnapshotsOperationInProgress &&
      !isSnapshotsOperationInProgress
    ) {
      if (!snapshotDetails) {
        // history(getParentPath(location.pathname));
        history(
          `${generatePath(ROUTES.BACKUPS_WORKLOAD, {
            organizationId: matchParams.organizationId,
            regionId: matchParams.regionId,
            projectId: matchParams.projectId,
            workloadId: matchParams.workloadId
          })}?${generateSearchString({
            tab: WORKLOAD_TAB_TITLES[WORKLOAD_TABS.BACKUPS]
          })}`
        );
      } else {
        dispatch(
          backupsActions.getBackupsWorkloadSnapshotDetails.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            workloadId: matchParams.workloadId!,
            snapshotId: matchParams.snapshotId!
          })
        );
      }
    }
  }, [
    history,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.workloadId,
    previousIsSnapshotsOperationInProgress,
    isSnapshotsOperationInProgress,
    snapshotDetails,
    matchParams.snapshotId
  ]);

  useEffect(() => {
    if (
      previousIsRestoresOperationInProgress &&
      !isRestoresOperationInProgress
    ) {
      dispatch(
        backupsActions.getRestores.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          snapshotId: matchParams.snapshotId!
        })
      );
    }
  }, [
    dispatch,
    isRestoresOperationInProgress,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.snapshotId,
    matchParams.workloadId,
    previousIsRestoresOperationInProgress
  ]);

  useEffect(() => {
    switch (activeTabIndex) {
      case TABS.RESTORES:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.RESTORES]
          })
        });
        break;
      case TABS.VMS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.VMS]
          })
        });
        break;
    }
  }, [history, activeTabIndex, dispatch]);

  return (
    <>
      <Head title={title} />
      {tableSnapshotDetails ? (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              {title}
            </s.Title>
            <s.ActionsContainer>
              {headerMenuItems.map((menuItem, index) => (
                <Tooltip title={menuItem.title} arrow key={index}>
                  <IconButton
                    onClick={() => menuItem.onClick && menuItem.onClick()}
                    color={menuItem.color}
                    disabled={menuItem.disabled}
                  >
                    {menuItem.icon}
                  </IconButton>
                </Tooltip>
              ))}
            </s.ActionsContainer>
          </s.SummaryContainer>
          <s.SummaryRow>
            <s.DetailsTitle>Backup ID: </s.DetailsTitle>
            <s.DetailsInfoColored>
              {tableSnapshotDetails.id}
            </s.DetailsInfoColored>
          </s.SummaryRow>
          {/* <s.SummaryRow>
            <s.DetailsTitle>Workload ID: </s.DetailsTitle>
            <s.DetailsInfoColored>
              {tableSnapshotDetails.workload_id}
            </s.DetailsInfoColored>
          </s.SummaryRow>
          <s.SummaryRow>
            <s.DetailsTitle>Created: </s.DetailsTitle>
            <s.DetailsInfoColored>
              {tableSnapshotDetails.created_at}
            </s.DetailsInfoColored>
          </s.SummaryRow> */}
          <s.SummaryRow>
            <s.DetailsTitle>Backup status: </s.DetailsTitle>
            {tableSnapshotDetails.status === "available" ? (
              <s.DetailsInfoColoredSuccess>
                {tableSnapshotDetails.status}
              </s.DetailsInfoColoredSuccess>
            ) : (
              <s.DetailsInfoColoredWarning>
                {tableSnapshotDetails.status}
              </s.DetailsInfoColoredWarning>
            )}
            <s.DetailsTitle>Type: </s.DetailsTitle>
            <s.Tag label={tableSnapshotDetails.snapshot_type} />
          </s.SummaryRow>
          {workloadDetails && (
            <>
              {/* <s.SummaryRow>
                <s.DetailsTitle>Workload ID: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {workloadDetails.id}
                </s.DetailsInfoColored>
                <s.DetailsTitle>Name: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {workloadDetails.name}
                </s.DetailsInfoColored>
              </s.SummaryRow> */}
              <s.SummaryRow>
                <s.DetailsTitle>Workload name: </s.DetailsTitle>
                <s.DetailsInfoColored>
                  {workloadDetails.name}
                </s.DetailsInfoColored>
                <s.DetailsTitle>Status: </s.DetailsTitle>
                {workloadDetails.status === "available" ? (
                  <s.DetailsInfoColoredSuccess>
                    {workloadDetails.status}
                  </s.DetailsInfoColoredSuccess>
                ) : (
                  <s.DetailsInfoColoredWarning>
                    {workloadDetails.status}
                  </s.DetailsInfoColoredWarning>
                )}
                <s.DetailsTitle>Type: </s.DetailsTitle>
                <s.Tag label={workloadDetails.workload_type_label} />
              </s.SummaryRow>
            </>
          )}
          <s.SummaryRow>
            <s.DetailsTitle>Backup Size: </s.DetailsTitle>
            <s.Tag label={tableSnapshotDetails.size} />
            <s.DetailsTitle>Restore size: </s.DetailsTitle>
            <s.Tag label={tableSnapshotDetails.restore_size} />
          </s.SummaryRow>
          <s.SummaryRow>
            <s.DetailsTitle>Backup progress: </s.DetailsTitle>
            <s.Tag label={tableSnapshotDetails.progress_percent} />
            <s.DetailsTitle>Time taken: </s.DetailsTitle>
            <s.Tag label={tableSnapshotDetails.time_taken} />
          </s.SummaryRow>

          {tableSnapshotDetails.description && (
            <s.SummaryRow>
              <s.DetailsTitle>Backup Description: </s.DetailsTitle>
              <s.DetailsInfoGray>
                {tableSnapshotDetails.description}
              </s.DetailsInfoGray>
            </s.SummaryRow>
          )}
          {tableSnapshotDetails.warning_msg && (
            <s.SummaryRow>
              <s.DetailsTitle>Warnings: </s.DetailsTitle>
              <s.DetailsInfoColoredWarning>
                {tableSnapshotDetails.warning_msg}
              </s.DetailsInfoColoredWarning>
            </s.SummaryRow>
          )}
          {tableSnapshotDetails.error_msg && (
            <s.SummaryRow>
              <s.DetailsTitle>Errors: </s.DetailsTitle>
              <s.DetailsInfoColoredDanger>
                {tableSnapshotDetails.error_msg}
              </s.DetailsInfoColoredDanger>
            </s.SummaryRow>
          )}

          <s.TabsContainer>
            <Tabs value={activeTabIndex} onChange={handleChangeTab}>
              {Object.values(TAB_TITLES).map((tabTitle) => (
                <Tab key={tabTitle} label={tabTitle} />
              ))}
            </Tabs>
          </s.TabsContainer>
          {tabContent[activeTabIndex]}
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            isLoading={dialogProps[dialog.type].isLoading}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
