import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteSweepIcon from "@mui/icons-material/DeleteSweep";
import { Button, IconButton, Tab, Tabs, Tooltip } from "@mui/material";
import { Breadcrumbs } from "components/common/Breadcrumbs";
import { Breadcrumb } from "components/common/Breadcrumbs/types";
import { FormDialog, selectOptionSchema } from "components/common/FormDialog";
import {
  FIELD_TYPES,
  FormDialogProps,
  SelectOption
} from "components/common/FormDialog/types";
import { Head } from "components/common/Head";
import { Loader } from "components/common/Loader";
import { Table } from "components/common/Table";
import {
  TABLE_SORTING_TYPES,
  TableColumn,
  TableMenuItem,
  TableMultiActionsItem,
  TableRowActionsMenuItem
} from "components/common/Table/types";
import moment from "moment-timezone";
import { useMount } from "hooks/useMount";
import { usePrevious } from "hooks/usePrevious";
import { useUnmount } from "hooks/useUnmount";

import * as backupsActions from "modules/backups/actions";
import {
  areBackupsSnapshotsLoadingSelector,
  areBackupsWorkloadSnapshotsMultiDeletingSelector,
  backupsSnapshotDetailsSelector,
  backupsSnapshotsSelector,
  backupsWorkloadDetailsAssignedVMsSelector,
  backupsWorkloadDetailsSelector,
  isBackupsSnapshotDetailsLoadingSelector,
  isBackupsWorkloadDeletingSelector,
  isBackupsWorkloadDetailsLoadingSelector,
  isBackupsWorkloadEditingSelector,
  isBackupsWorkloadSnapshotCancelingSelector,
  isBackupsWorkloadSnapshotCreatingSelector,
  isBackupsWorkloadSnapshotDeletingSelector
} from "modules/backups/selectors";
import {
  BackupsWorkloadSnapshot,
  FULL_BACKUP_INTERVALS_TYPES,
  FULL_BACKUP_INTERVALS_TYPES_LABELS,
  RETENTION_TYPES,
  RETENTION_TYPES_LABELS,
  TableBackupsWorkloadDetailsAssignedVMs
} from "modules/backups/types";
import * as enterprisesActions from "modules/enterprises/actions";
import { organizationSelector } from "modules/enterprises/selectors";
import * as instancesActions from "modules/instances/actions";
import * as pollingActions from "modules/polling/actions";
import * as projectsActions from "modules/projects/actions";
import { projectSelector } from "modules/projects/selectors";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { array, number, object, string } from "yup";
import { DATE_FORMATS, ERROR_MESSAGES, ROUTES } from "../../constants";
import * as s from "./styles";
import { DIALOG_TYPES, TABS } from "./types";
import { format } from "date-fns";
import dayjs from "dayjs";
import {
  instancesSelector,
  selectOptionFlavorsSelector
} from "modules/instances/selectors";
import { getSelectOption } from "utils/getSelectOption";
import { getParentPath } from "utils/getParentPath";
import { generateSearchString } from "utils/generateSearchString";
import { formatDate } from "utils/formatDate";
import { FLAVOR_TYPE } from "modules/instances/types";

const POLL_ID_PREFIX = "BACKUP_WORKLOAD";
const POLL_IDS = {
  workloadInfo: "WORKLOAD_INFO",
  snapshots: "SNAPSHOTS",
  vms: "VMs",
  networks: "NETWORKS",
  networkSubnets: "SUBNETS"
};

const backupsSnapshotsTableColumns: TableColumn<BackupsWorkloadSnapshot>[] = [
  { key: "name", label: "Name" },
  { key: "id", label: "ID" },
  { key: "snapshot_type", label: "Type" },
  { key: "status", label: "Status" },
  { key: "created_at", label: "Created", sortingType: TABLE_SORTING_TYPES.DATE }
];

const assignedVMsTableColumns: TableColumn<TableBackupsWorkloadDetailsAssignedVMs>[] =
  [
    { key: "name", label: "Name" },
    { key: "id", label: "ID" }
  ];

export const TAB_TITLES: { [key in TABS]: string } = {
  [TABS.WORKLOAD_INFO]: "Workload Info",
  [TABS.VMS]: "Assigned VMs",
  [TABS.BACKUPS]: "Created Backups"
};

export const BackupsWorkload: FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const matchParams = useParams<{
    organizationId: string;
    projectId: string;
    regionId: string;
    workloadId: string;
  }>();
  const organization = useSelector(organizationSelector);
  const project = useSelector(projectSelector);
  const virtualMachines = useSelector(instancesSelector);
  const flavors = useSelector(selectOptionFlavorsSelector);

  const workloadDetails = useSelector(backupsWorkloadDetailsSelector);
  const isWorkloadDetailsLoading = useSelector(
    isBackupsWorkloadDetailsLoadingSelector
  );
  const assignedVMs = useSelector(backupsWorkloadDetailsAssignedVMsSelector);
  const workloadSnapshots = useSelector(backupsSnapshotsSelector);
  const areWorkloadSnapshotsLoading = useSelector(
    areBackupsSnapshotsLoadingSelector
  );
  const isBackupsWorkloadSnapshotCreating = useSelector(
    isBackupsWorkloadSnapshotCreatingSelector
  );
  const isBackupsWorkloadSnapshotCanceling = useSelector(
    isBackupsWorkloadSnapshotCancelingSelector
  );
  const isBackupsWorkloadSnapshotDeleting = useSelector(
    isBackupsWorkloadSnapshotDeletingSelector
  );
  const prevIsBackupsWorkloadSnapshotDeleting = usePrevious(
    isBackupsWorkloadSnapshotDeleting
  );
  const areBackupsWorkloadSnapshotsMultiDeleting = useSelector(
    areBackupsWorkloadSnapshotsMultiDeletingSelector
  );
  const prevAreBackupsWorkloadSnapshotsMultiDeleting = usePrevious(
    areBackupsWorkloadSnapshotsMultiDeleting
  );

  const isSnapshotsOperationInProgress =
    isBackupsWorkloadSnapshotCreating ||
    isBackupsWorkloadSnapshotCanceling ||
    isBackupsWorkloadSnapshotDeleting ||
    areBackupsWorkloadSnapshotsMultiDeleting;
  const previousIsSnapshotsOperationInProgress = usePrevious(
    isSnapshotsOperationInProgress
  );
  const snapshotDetails = useSelector(backupsSnapshotDetailsSelector);
  const isSnapshotDetailsLoading = useSelector(
    isBackupsSnapshotDetailsLoadingSelector
  );

  const isBackupsWorkloadEditing = useSelector(
    isBackupsWorkloadEditingSelector
  );
  const isBackupsWorkloadDeleting = useSelector(
    isBackupsWorkloadDeletingSelector
  );

  const isWorkloadOperationInProgress =
    isBackupsWorkloadEditing || isBackupsWorkloadDeleting;
  const previousIsWorkloadOperationInProgress = usePrevious(
    isWorkloadOperationInProgress
  );

  const title = `Workload: ${workloadDetails?.name}`;
  const breadcrumbs: Breadcrumb[] = [
    { text: "Organizations", url: ROUTES.ORGANIZATIONS },
    {
      text: organization?.name || "",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: "Projects",
      url: generatePath(ROUTES.ORGANIZATION, {
        organizationId: matchParams.organizationId
      })
    },
    {
      text: project?.name || "",
      url: generatePath(ROUTES.PROJECT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: "Backups Workloads",
      url: generatePath(ROUTES.BACKUPS_WORKLOADS, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId
      })
    },
    {
      text: workloadDetails?.name || "",
      url: generatePath(ROUTES.BACKUPS_WORKLOAD, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        workloadId: matchParams.workloadId
      })
    }
  ];

  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [resetSnapshotsCheckboxes, setResetSnapshotCheckboxes] =
    useState(false);

  const handleSwitchOffResetCheckboxes = useCallback(
    () => setResetSnapshotCheckboxes(false),
    []
  );

  const handleSelectRows = (ids: string[]) => {
    setCheckedRows(ids);
  };

  const handleMultiDeleteSnapshotsMenuItemClick = useCallback(
    (ids: string[]) => {
      setCheckedRows(ids);
      // handleSelectRows(ids);
      // setResetSnapshotCheckboxes(false);
      setDialog({
        type: DIALOG_TYPES.MULTI_DELETE,
        isOpened: true
      });
    },
    []
  );

  const activeTabIndexFromParam = Object.keys(TAB_TITLES).find(
    (key) =>
      TAB_TITLES[key] &&
      String(TAB_TITLES[key]).toLowerCase() ===
        new URLSearchParams(location.search).get("tab")
  );
  const [activeTabIndex, setActiveTabIndex] = useState(
    Number(activeTabIndexFromParam || TABS.WORKLOAD_INFO) as TABS
  );
  const handleChangeTab = useCallback((e, value: number) => {
    setActiveTabIndex(value);
    setCheckedRows([]);
  }, []);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);

  const [dialog, setDialog] = useState<{
    isOpened: boolean;
    type: DIALOG_TYPES;
  }>({ type: DIALOG_TYPES.CREATE_SNAPSHOT, isOpened: false });

  const handleCloseDialog = useCallback(() => {
    setDialog({
      ...dialog,
      isOpened: false
    });
    setSelectedItemId(null);
    // setCheckedRows([]);
  }, [dialog]);

  const handleDialogOpen = useCallback(
    (dialogType: DIALOG_TYPES, id?: string) => {
      if (id) setSelectedItemId(id);
      if (dialogType === DIALOG_TYPES.RESTORE) {
        dispatch(
          backupsActions.getBackupsWorkloadSnapshotDetails.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            workloadId: matchParams.workloadId!,
            snapshotId: id!
          })
        );
      }
      setDialog({
        type: dialogType,
        isOpened: true
      });
    },
    [
      dispatch,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      matchParams.workloadId
    ]
  );

  const headerMenuItems: TableMenuItem[] = [
    {
      title: "Delete selected backups",
      icon: <DeleteSweepIcon />,
      color: "inherit",
      onClick: () => handleMultiDeleteSnapshotsMenuItemClick(checkedRows),
      disabled: checkedRows.length === 0
    },
    {
      title: "Edit workload",
      icon: <EditIcon />,
      color: "inherit",
      onClick: () => handleDialogOpen(DIALOG_TYPES.EDIT_WORKLOAD)
    },
    {
      title: "Delete workload",
      icon: <DeleteIcon />,
      color: "inherit",
      onClick: () => handleDialogOpen(DIALOG_TYPES.DELETE_WORKLOAD)
    }
  ];

  const snapshotsTableMultiActions: TableMultiActionsItem<BackupsWorkloadSnapshot>[] =
    [
      {
        label: "Delete",
        handler: handleMultiDeleteSnapshotsMenuItemClick,
        icon: <DeleteSweepIcon />
      }
    ];

  const backupsSnapshotsTableActions: TableRowActionsMenuItem<BackupsWorkloadSnapshot>[] =
    [
      {
        label: "Cancel backup",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.CANCEL_SNAPSHOT, id)
        // isDisabled: (snapshot) =>
        //   snapshot.status !== "executing" || snapshot.status !== "uploading"
      },
      {
        label: "Delete backup",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.DELETE_SNAPSHOT, id)
      },
      {
        label: "Restore from backup",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.RESTORE, id)
        // isDisabled: (snapshot) => snapshot.status === "available"
      }
    ];

  const assignedVMsTableActions: TableRowActionsMenuItem<TableBackupsWorkloadDetailsAssignedVMs>[] =
    [
      {
        label: "Unassign VM",
        handler: (id) => handleDialogOpen(DIALOG_TYPES.CREATE_SNAPSHOT, id)
      }
    ];

  const handleConfirmCreateBackupsWorkloadSnapshot = useCallback(
    (data: { name: string; description: string }) => {
      dispatch(
        backupsActions.createBackupsWorkloadSnapshot.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          data: {
            name: data.name,
            description: data.description
          }
        })
      );
      handleCloseDialog();
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      matchParams.workloadId
    ]
  );

  const handleConfirmDeleteBackupsWorkloadSnapshot = useCallback(() => {
    let id = "";
    if (selectedItemId) {
      id = selectedItemId;
    } else if (checkedRows.length > 0) {
      id = checkedRows[0];
    }
    // if (selectedItemId) {
    if (id) {
      dispatch(
        backupsActions.deleteBackupsWorkloadSnapshot.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          id
          // id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    checkedRows,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.workloadId
  ]);

  const handleConfirmDeleteBackupsWorkloadSnapshotMulti = useCallback(() => {
    if (checkedRows.length > 0) {
      dispatch(
        backupsActions.multiDeleteBackupsWorkloadSnapshots.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          snapshot_ids: checkedRows
        })
      );
    }
    handleCloseDialog();
  }, [
    checkedRows,
    dispatch,
    handleCloseDialog,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.workloadId
  ]);

  const handleConfirmCancelBackupsWorkloadSnapshot = useCallback(() => {
    if (selectedItemId) {
      dispatch(
        backupsActions.cancelBackupsWorkloadSnapshot.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!,
          id: selectedItemId
        })
      );
    }
    handleCloseDialog();
  }, [
    selectedItemId,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.workloadId
  ]);

  const handleConfirmDeleteWorkload = useCallback(() => {
    if (workloadDetails) {
      dispatch(
        backupsActions.deleteBackupsWorkload.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          id: workloadDetails.id
        })
      );
    }
    handleCloseDialog();
  }, [
    workloadDetails,
    handleCloseDialog,
    dispatch,
    matchParams.regionId,
    matchParams.organizationId,
    matchParams.projectId
  ]);

  const handleConfirmEditWorkload = useCallback(
    (data: {
      name?: string;
      description?: string;
      instances?: SelectOption[];
      workload_type?: string;
      retention_policy_type?: RETENTION_TYPES;
      retention_snapshots?: number;
      retention_days?: number;
      fullbackup_interval_rdo?: FULL_BACKUP_INTERVALS_TYPES;
      snapshots_number?: number;
      pause_at_snapshot?: boolean;
      scheduler_enabled?: boolean;
      start_date?: string;
      end_date?: string;
      start_time?: string;
      timezone?: SelectOption;
      interval?: number;
    }) => {
      if (workloadDetails) {
        dispatch(
          backupsActions.editBackupsWorkload.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            id: workloadDetails.id,
            data: {
              name: data.name,
              description: data.description,
              workload_type_id: data.workload_type,
              instances: data.instances?.map((instance) => ({
                "instance-id": instance.value
              })),
              jobschedule: {
                retention_policy_type: data.retention_policy_type,
                retention_policy_value:
                  data.retention_policy_type === RETENTION_TYPES.DAYS
                    ? data.retention_days
                      ? data.retention_days
                      : undefined
                    : data.retention_snapshots
                      ? data.retention_snapshots
                      : undefined,
                enabled: data.scheduler_enabled,
                timezone: data.timezone?.value,
                start_date:
                  data.start_date &&
                  format(new Date(data.start_date), "MM/dd/yyyy"),
                end_date:
                  data.end_date !== null
                    ? data.end_date &&
                      format(new Date(data.end_date), "MM/dd/yyyy")
                    : "",
                start_time:
                  data.start_time &&
                  format(new Date(data.start_time), "hh:mm a"),
                interval: data.interval ? `${data.interval} hr` : undefined,
                fullbackup_interval:
                  data.fullbackup_interval_rdo ===
                  FULL_BACKUP_INTERVALS_TYPES.ALWAYS
                    ? "0"
                    : data.fullbackup_interval_rdo ===
                        FULL_BACKUP_INTERVALS_TYPES.NEVER
                      ? "-1"
                      : String(data.snapshots_number)
              }
            }
          })
        );
        handleCloseDialog();
      }
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      workloadDetails
    ]
  );

  const handleConfirmAssignVmWorkload = useCallback(
    (data: { instances?: SelectOption[] }) => {
      if (workloadDetails) {
        dispatch(
          backupsActions.editBackupsWorkload.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            id: workloadDetails.id,
            data: {
              instances: data.instances?.map((instance) => ({
                "instance-id": instance.value
              }))
            }
          })
        );
        handleCloseDialog();
      }
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      workloadDetails
    ]
  );

  // use when will be opened inPlace Restore for clients
  // const handleConfirmRestore = useCallback(
  //   (data: {
  //     name: string;
  //     description?: string;
  //     instances?: SelectOption[];
  //     restore_type: string;
  //   }) => {
  //     if (snapshotDetails && selectedItemId) {
  //       if ((data.restore_type as RESTORE_TYPES) === RESTORE_TYPES.SELECTIVE) {
  //         dispatch(
  //           backupsActions.createSelectiveRestore.started({
  //             region: matchParams.regionId!,
  //             orgId: matchParams.organizationId!,
  //             projectId: matchParams.projectId!,
  //             workloadId: matchParams.workloadId!,
  //             snapshotId: selectedItemId,
  //             data: {
  //               name: data.name,
  //               description: data.description || "",
  //               instance_ids: data.instances?.map((instance) => instance.value)
  //             }
  //           })
  //         );
  //         handleCloseDialog();
  //       }

  //       if ((data.restore_type as RESTORE_TYPES) === RESTORE_TYPES.INPLACE) {
  //         dispatch(
  //           backupsActions.createInPlaceRestore.started({
  //             region: matchParams.regionId!,
  //             orgId: matchParams.organizationId!,
  //             projectId: matchParams.projectId!,
  //             workloadId: matchParams.workloadId!,
  //             snapshotId: snapshotDetails.id,
  //             data: {
  //               restore: {
  //                 name: data.name,
  //                 description: data.description || "",
  //                 options: {
  //                   openstack: {
  //                     instances: (data.instances || []).map((instance) => {
  //                       return {
  //                         name: instance.label,
  //                         id: instance.value,
  //                         include: true,
  //                         restore_boot_disk: true,
  //                         availability_zone: "nova",
  //                         vdisks: []
  //                         // vdisks: [],
  //                       };
  //                     })
  //                   }
  //                 }
  //               }
  //             }
  //           })
  //         );
  //         handleCloseDialog();
  //       }
  //     }
  //   },
  //   [
  //     dispatch,
  //     handleCloseDialog,
  //     matchParams.organizationId,
  //     matchParams.projectId,
  //     matchParams.regionId,
  //     matchParams.workloadId,
  //     selectedItemId,
  //     snapshotDetails
  //   ]
  // );

  const handleConfirmRestore = useCallback(
    (data: {
      name: string;
      // description?: string;
      // instances?: SelectOption[];
      [key: string]: any;
    }) => {
      if (snapshotDetails && selectedItemId) {
        const selectedInstances = (snapshotDetails?.instances ?? [])
          .filter((vm) => data[vm.id]) // Filter selected vms be checkbox
          .map((vm) => ({
            id: vm.id,
            instance_name: data[`name-${vm.id}`], // set new name,
            flavor_id: data[`flavor-${vm.id}`]
              ? (data[`flavor-${vm.id}`] as SelectOption).value
              : undefined
          }));

        dispatch(
          backupsActions.createSelectiveRestore.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            workloadId: matchParams.workloadId!,
            snapshotId: selectedItemId,
            data: {
              name: data.name,
              description: data.description || "",
              instances: selectedInstances
              // instance_ids: data.instances?.map((instance) => instance.value)
            }
          })
        );
        handleCloseDialog();
      }
    },
    [
      dispatch,
      handleCloseDialog,
      matchParams.organizationId,
      matchParams.projectId,
      matchParams.regionId,
      matchParams.workloadId,
      selectedItemId,
      snapshotDetails
    ]
  );

  const generateVmsTableItemURL = useCallback(
    (id: string) => {
      const isDeleted: boolean = virtualMachines?.find((vm) => vm.id === id)
        ? false
        : true;
      if (!isDeleted) {
        const url = generatePath(ROUTES.VIRTUAL_MACHINE, {
          organizationId: matchParams.organizationId,
          regionId: matchParams.regionId,
          projectId: matchParams.projectId,
          virtualMachineId: id
        });
        return url;
      } else {
        return "";
      }
    },
    [
      virtualMachines,
      matchParams.organizationId,
      matchParams.regionId,
      matchParams.projectId
    ]
  );

  const generateSnapshotsTableItemURL = useCallback(
    (id: string) =>
      generatePath(ROUTES.BACKUPS_SNAPSHOT, {
        organizationId: matchParams.organizationId,
        regionId: matchParams.regionId,
        projectId: matchParams.projectId,
        workloadId: matchParams.workloadId,
        snapshotId: id
      }),
    [
      matchParams.organizationId,
      matchParams.regionId,
      matchParams.projectId,
      matchParams.workloadId
    ]
  );

  const previousSelectedItemId = usePrevious(selectedItemId);
  const currentItemId = selectedItemId
    ? selectedItemId
    : previousSelectedItemId;
  const currentSnapshotName = workloadSnapshots?.find(
    (snapshot) => snapshot.id === currentItemId
  )?.name;
  const currentSnapshotId = workloadSnapshots?.find(
    (snapshot) => snapshot.id === currentItemId
  )?.id;

  const deletingSnapshotsNames = workloadSnapshots
    ?.filter((snapshot) => checkedRows?.includes(snapshot.id))
    .map((snapshot) => snapshot.name);

  const deletingSnapshotsIds = workloadSnapshots
    ?.filter((snapshot) => checkedRows?.includes(snapshot.id))
    .map((snapshot) => snapshot.id);

  const timeZones = moment.tz.names();
  const currentTimeZone = workloadDetails?.timezone;

  const tabContent = [
    <>
      {workloadDetails ? (
        <>
          <s.InfoDescription>
            Created: {workloadDetails.created_at} <br />
            Updated: {workloadDetails.updated_at}
          </s.InfoDescription>
          <s.StyledPaper variant="outlined">
            <s.DetailsRow>
              <s.DetailsBlock>
                <s.InfoTitle variant={"h5"} component={"h6"}>
                  General Information
                </s.InfoTitle>
                <s.SummaryRow>
                  <s.DetailsTitle>Workload type: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.workload_type_label}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                {/* <s.SummaryRow>
                  <s.DetailsTitle>Availability zone: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.availability_zone}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow> */}
                <s.SummaryRow>
                  <s.DetailsTitle>Retention policy type: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.retention_policy_type_label}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Retention policy value: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.retention_policy_value_str}
                  </s.DetailsInfoColoredDark>
                  {/* <s.Tag label={workloadDetails.retention_policy_value} /> */}
                </s.SummaryRow>
                {workloadDetails.description && (
                  <s.SummaryRow>
                    <s.DetailsTitle>Description: </s.DetailsTitle>
                    <s.DetailsInfoColoredDark>
                      {workloadDetails.description}
                    </s.DetailsInfoColoredDark>
                  </s.SummaryRow>
                )}
                {workloadDetails.error_msg && (
                  <s.SummaryRow>
                    <s.DetailsTitle>Errors: </s.DetailsTitle>
                    <s.DetailsInfoColoredWarning>
                      {workloadDetails.error_msg}
                    </s.DetailsInfoColoredWarning>
                  </s.SummaryRow>
                )}
              </s.DetailsBlock>

              <s.DetailsBlock>
                <s.InfoTitle variant={"h5"} component={"h6"}>
                  Schedule Details
                </s.InfoTitle>
                <s.SummaryRow>
                  <s.DetailsTitle>Scheduler: </s.DetailsTitle>
                  {workloadDetails.schedule_enabled ? (
                    <s.DetailsInfoColoredSuccess>
                      {"enabled"}
                    </s.DetailsInfoColoredSuccess>
                  ) : (
                    <s.DetailsInfoColoredDanger>
                      {"disabled"}
                    </s.DetailsInfoColoredDanger>
                  )}
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Start date: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {formatDate(
                      new Date(workloadDetails.start_date),
                      DATE_FORMATS.DATE
                    )}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>End date: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.end_date === "No End"
                      ? workloadDetails.end_date
                      : formatDate(
                          new Date(workloadDetails.end_date),
                          DATE_FORMATS.DATE
                        )}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Start time: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.start_time}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Timezone: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.timezone}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Schedule interval: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.schedule_interval_label}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Full backups interval: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {(() => {
                      const interval = workloadDetails?.fullbackup_interval;
                      if (Number(interval) === 0)
                        return FULL_BACKUP_INTERVALS_TYPES_LABELS.ALWAYS;
                      if (Number(interval) === -1)
                        return FULL_BACKUP_INTERVALS_TYPES_LABELS.NEVER;
                      return `every ${interval} incremental`;
                    })()}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
              </s.DetailsBlock>

              <s.DetailsBlock>
                <s.InfoTitle variant={"h5"} component={"h6"}>
                  Storage Usage
                </s.InfoTitle>
                <s.SummaryRow>
                  <s.DetailsTitle>Total usage: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.storage_usage_total}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Full backups count: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.storage_usage_full.snap_count}
                  </s.DetailsInfoColoredDark>
                  <s.DetailsTitle>Usage: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.storage_usage_full.usage}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
                <s.SummaryRow>
                  <s.DetailsTitle>Incremental backups count: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.storage_usage_incremental.snap_count}
                  </s.DetailsInfoColoredDark>
                  <s.DetailsTitle>Usage: </s.DetailsTitle>
                  <s.DetailsInfoColoredDark>
                    {workloadDetails.storage_usage_incremental.usage}
                  </s.DetailsInfoColoredDark>
                </s.SummaryRow>
              </s.DetailsBlock>
            </s.DetailsRow>
          </s.StyledPaper>
        </>
      ) : (
        <Loader text={"Loading user information..."} />
      )}
    </>,
    <>
      <Table<TableBackupsWorkloadDetailsAssignedVMs>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!assignedVMs}
        key={"assignedVMsTable"}
        rows={assignedVMs || []}
        columns={assignedVMsTableColumns}
        // actions={assignedVMsTableActions}
        itemLink={{
          column: "name",
          getURL: generateVmsTableItemURL
        }}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.CHANGE_ASSIGNED_VMs)}
            variant={"contained"}
            disabled={!assignedVMs || !virtualMachines}
          >
            Change assigned VM
          </Button>
        }
      />
    </>,
    <>
      <Table<BackupsWorkloadSnapshot>
        isSearchEnabled={true}
        isSortingEnabled={true}
        isLoading={!workloadSnapshots}
        key={"userOrganizationsTable"}
        rows={workloadSnapshots || []}
        columns={backupsSnapshotsTableColumns}
        actions={backupsSnapshotsTableActions}
        isSelectingEnabled={true}
        multiActions={snapshotsTableMultiActions}
        resetCheckboxes={resetSnapshotsCheckboxes}
        onResetCheckboxes={handleSwitchOffResetCheckboxes}
        onSelectRows={handleSelectRows}
        itemLink={{
          column: "name",
          getURL: generateSnapshotsTableItemURL
        }}
        toolbarItems={
          <Button
            onClick={() => handleDialogOpen(DIALOG_TYPES.CREATE_SNAPSHOT)}
            variant={"contained"}
            // disabled={!workloadSnapshots}
          >
            Create backup
          </Button>
        }
      />
    </>
  ];

  const dialogProps: {
    [key in DIALOG_TYPES]: Omit<FormDialogProps, "isOpened" | "onCancel">;
  } = {
    [DIALOG_TYPES.CREATE_SNAPSHOT]: {
      onConfirm: handleConfirmCreateBackupsWorkloadSnapshot,
      title: "Create Backup",
      confirmButtonLabel: "Create",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string().required().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME)
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          rules: string().max(1024, ERROR_MESSAGES.MAX_BACKUPS_DESCRIPTION)
        },
        {
          name: "creteBackupInfo",
          type: FIELD_TYPES.NOTES,
          label: `\n\nℹ️ Reminder: ensure the workload is in available state before attempting to take a backup.`
        }
      ]
    },
    [DIALOG_TYPES.CANCEL_SNAPSHOT]: {
      onConfirm: handleConfirmCancelBackupsWorkloadSnapshot,
      title: `Are you sure you want to terminate "${
        currentSnapshotName ?? "selected"
      }" backup?`,
      confirmButtonLabel: "Terminate"
    },
    [DIALOG_TYPES.DELETE_SNAPSHOT]: {
      onConfirm: handleConfirmDeleteBackupsWorkloadSnapshot,
      title: `Are you sure you want to delete "${
        currentSnapshotName ?? "selected"
      }" backup?`,
      fields: [
        {
          name: "deletionInfo",
          type: FIELD_TYPES.NOTES,
          label: `\nℹ️ Reminder: backup can be deleted only when it is in available, error, or canceled state;\n\nand the current workload is in available state.`
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.MULTI_DELETE]: {
      onConfirm:
        checkedRows?.length > 1
          ? handleConfirmDeleteBackupsWorkloadSnapshotMulti
          : handleConfirmDeleteBackupsWorkloadSnapshot,
      title: `Are you sure you want to delete next backups?`,
      fields: [
        {
          name: "names",
          type: FIELD_TYPES.LABEL,
          label: deletingSnapshotsIds
            ? deletingSnapshotsIds
                .map((name) => `\u2794\u00A0\u00A0${name}`)
                .join("\n")
            : ""
        },
        {
          name: "deletionInfo",
          type: FIELD_TYPES.NOTES,
          label: `\n\nℹ️ Reminder: backup can be deleted only when it is in available, error, or canceled state;\n\nand the current workload is in available state.`
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.DELETE_WORKLOAD]: {
      isLocked: false,
      onConfirm: handleConfirmDeleteWorkload,
      title: `Are you sure you want to delete "${
        workloadDetails?.name ?? "selected"
      }" workload?`,
      fields: [
        {
          name: "confirmationName",
          type: FIELD_TYPES.TEXT,
          label: "Type the workload name to confirm deletion",
          rules: string()
            .required()
            .test({
              name: "validateConfirmationName",
              message: "Workload name does not match",
              test: function (value) {
                return value === workloadDetails?.name;
              }
            })
        },
        {
          name: "deletionInfo",
          type: FIELD_TYPES.NOTES,
          label: `\n\nℹ️ Please remove the all backups before proceeding with workload deletion.`
        }
      ],
      confirmButtonLabel: "Delete"
    },
    [DIALOG_TYPES.CHANGE_ASSIGNED_VMs]: {
      onConfirm: handleConfirmAssignVmWorkload,
      title: "Change workload's assigned VMs",
      confirmButtonLabel: "Assign",
      fields: [
        {
          name: "instances",
          type: FIELD_TYPES.MULTISELECT,
          label: "Instances",
          options: virtualMachines?.map((virtualMachine) =>
            getSelectOption(virtualMachine, "name", "id")
          ),
          defaultValue: workloadDetails?.instances.map((vm) =>
            getSelectOption(vm, "name", "id")
          ),
          rules: array(selectOptionSchema)
        },
        {
          name: "instancesInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return !!(
              fieldValues.instances &&
              (fieldValues.instances as SelectOption[]).length === 0
            );
          },
          label:
            "ℹ️ Ensure that the instance you choose isn't already assigned to any existing workloads"
        },
        {
          name: "instancesInfo2",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return !!(
              fieldValues.instances &&
              (fieldValues.instances as SelectOption[]).length !== 0
            );
          },
          label:
            "⚠️ At least one instance, should be selected to be the part of workload definition."
        }
      ]
    },
    [DIALOG_TYPES.EDIT_WORKLOAD]: {
      onConfirm: handleConfirmEditWorkload,
      title: "Edit Workload",
      confirmButtonLabel: "Edit",
      fields: [
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Name",
          rules: string().required().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME),
          defaultValue: workloadDetails?.name
        },
        {
          name: "description",
          type: FIELD_TYPES.TEXT,
          label: "Description",
          defaultValue: workloadDetails?.description,
          rules: string().max(1024, ERROR_MESSAGES.MAX_BACKUPS_DESCRIPTION)
        },
        {
          name: "instances",
          type: FIELD_TYPES.MULTISELECT,
          label: "Instances",
          options: virtualMachines?.map((virtualMachine) =>
            getSelectOption(virtualMachine, "name", "id")
          ),
          defaultValue: workloadDetails?.instances.map((vm) =>
            getSelectOption(vm, "name", "id")
          ),
          rules: array(selectOptionSchema)
        },
        {
          name: "instancesInfo",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return !!(
              fieldValues.instances &&
              (fieldValues.instances as SelectOption[]).length === 0
            );
          },
          label:
            "ℹ️ Ensure that the instance you choose isn't already assigned to any existing workloads"
        },
        {
          name: "instancesInfo2",
          type: FIELD_TYPES.NOTES,
          isHidden: (fieldValues) => {
            return !!(
              fieldValues.instances &&
              (fieldValues.instances as SelectOption[]).length !== 0
            );
          },
          label:
            "⚠️ At least one instance, should be selected to be the part of workload definition."
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Retention policy type:"
        },
        {
          name: "retention_policy_type",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Retention policy type",
          helperText: "Choose retention policy type",
          options: Object.keys(RETENTION_TYPES_LABELS).map((x) => ({
            label: RETENTION_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: workloadDetails?.retention_policy_type,
          rules: string()
        },
        {
          name: "retention_snapshots",
          type: FIELD_TYPES.NUMBER,
          label: "Number of backups to keep",
          suffix: "backups",
          defaultValue: workloadDetails?.retention_policy_value || 30,
          min: 1,
          max: 365,
          isHidden: (fieldValues) => {
            return (
              fieldValues.retention_policy_type !== RETENTION_TYPES.SNAPSHOTS
            );
          },
          rules: number()
            .integer()
            .min(1, `${ERROR_MESSAGES.BACKUPS_SNAPSHOTS_RETENTION}.`)
            .max(365, `${ERROR_MESSAGES.BACKUPS_SNAPSHOTS_RETENTION}.`)
        },
        {
          name: "retention_days",
          type: FIELD_TYPES.NUMBER,
          defaultValue: workloadDetails?.retention_policy_value || 30,
          label: "Number of days to retain backups",
          suffix: "days",
          min: 1,
          max: 365,
          isHidden: (fieldValues) => {
            return fieldValues.retention_policy_type !== RETENTION_TYPES.DAYS;
          },
          rules: number()
            .integer()
            .min(1, `${ERROR_MESSAGES.BACKUPS_DAYS_RETENTION}.`)
            .max(365, `${ERROR_MESSAGES.BACKUPS_DAYS_RETENTION}.`)
        },
        {
          name: "divider2",
          type: FIELD_TYPES.DIVIDER,
          label: "Full backup interval:"
        },
        {
          name: "fullbackup_interval_rdo",
          type: FIELD_TYPES.TOGGLE_BUTTON,
          label: "Full Backup Interval",
          helperText: "Select full backup frequency",
          options: Object.keys(FULL_BACKUP_INTERVALS_TYPES_LABELS).map((x) => ({
            label: FULL_BACKUP_INTERVALS_TYPES_LABELS[x],
            value: x
          })),
          defaultValue: () => {
            if (Number(workloadDetails?.fullbackup_interval) === 0)
              return FULL_BACKUP_INTERVALS_TYPES.ALWAYS;
            if (Number(workloadDetails?.fullbackup_interval) === -1)
              return FULL_BACKUP_INTERVALS_TYPES.NEVER;
            return FULL_BACKUP_INTERVALS_TYPES.SNAPSHOTS;
          },
          rules: string()
        },
        {
          name: "snapshots_number",
          type: FIELD_TYPES.NUMBER,
          defaultValue: () => {
            if (Number(workloadDetails?.fullbackup_interval) <= 0) return 0;
            else return Number(workloadDetails?.fullbackup_interval);
          },
          label: "Number of incremental backups",
          suffix: "backups",
          min: -1,
          isHidden: (fieldValues) => {
            return (
              fieldValues.fullbackup_interval_rdo !==
              FULL_BACKUP_INTERVALS_TYPES.SNAPSHOTS
            );
          },
          rules: number().integer()
        },
        // {
        //   name: "pause_at_snapshot",
        //   type: FIELD_TYPES.TOGGLE,
        //   defaultValue: workloadDetails,
        //   label: "Pause VM"
        // },
        {
          name: "divider3",
          type: FIELD_TYPES.DIVIDER,
          label: "Schedule Settings"
        },
        {
          name: "scheduler_enabled",
          type: FIELD_TYPES.TOGGLE,
          defaultValue: workloadDetails?.schedule_enabled,
          fullWidth: true,
          label: "Enable scheduler"
        },
        {
          name: "start_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "Start Date",
          helperText: `Edit start date.`,
          defaultValue: dayjs(workloadDetails?.start_date),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        },
        {
          name: "end_date",
          type: FIELD_TYPES.DATE_PICKER,
          label: "End Date",
          helperText: `Edit end date.\nLeave the field empty to indicate 'never'.`,
          defaultValue:
            workloadDetails?.end_date === "No End" || !workloadDetails?.end_date
              ? null
              : dayjs(workloadDetails?.end_date),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        },
        {
          name: "start_time",
          type: FIELD_TYPES.STATIC_TIME_PICKER,
          label: "Change UTC time of interval start",
          defaultValue: dayjs(workloadDetails?.start_time, "hh:mm A"),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        },
        // {
        //   name: "timezone",
        //   type: FIELD_TYPES.SELECT,
        //   label: "Time Zone",
        //   variant: "outlined",
        //   options: timeZones.map((timeZone) => ({
        //     label:
        //       timeZone === currentTimeZone ? `${timeZone} (Current)` : timeZone,
        //     value: timeZone
        //   })),
        //   defaultValue: {
        //     label: currentTimeZone,
        //     value: currentTimeZone
        //   },
        //   isHidden: (fieldValues) => {
        //     return !fieldValues.scheduler_enabled;
        //   }
        // },
        {
          name: "interval",
          type: FIELD_TYPES.NUMBER,
          defaultValue: workloadDetails?.schedule_interval
            ? workloadDetails?.schedule_interval.split(" ")[0]
            : 24,
          label: "Repeat every (in hrs)",
          suffix: "hrs",
          min: 1,
          rules: number().integer(),
          isHidden: (fieldValues) => {
            return !fieldValues.scheduler_enabled;
          }
        }
      ]
    },
    [DIALOG_TYPES.RESTORE]: {
      onConfirm: handleConfirmRestore,
      isLoading: isSnapshotDetailsLoading,
      title: "Restore from backup",
      confirmButtonLabel: "Restore",
      fields: [
        // {
        //   name: "restore_type",
        //   type: FIELD_TYPES.TOGGLE_BUTTON,
        //   label: "Restore type",
        //   helperText: `Choose restore type:\nSelective - creates new VM;\nInPlace - restore current VM`,
        //   options: Object.keys(RESTORE_TYPES_LABELS).map((x) => ({
        //     label: RESTORE_TYPES_LABELS[x],
        //     value: x
        //   })),
        //   defaultValue: RESTORE_TYPES.SELECTIVE,
        //   rules: string()
        // },
        {
          name: "name",
          type: FIELD_TYPES.TEXT,
          label: "Restore name",
          rules: string().required().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME)
        },
        {
          name: "divider",
          type: FIELD_TYPES.DIVIDER,
          label: "Select instances to restore:"
        },
        ...(snapshotDetails?.instances ?? [])
          .map((vm) => [
            {
              name: vm.id,
              type: FIELD_TYPES.CHECKBOX,
              defaultValue: false,
              fullWidth: true,
              margin: "8px 0 0 0",
              label: `${vm.name}`
            },
            {
              name: `name-${vm.id}`,
              type: FIELD_TYPES.TEXT,
              defaultValue: `${vm.name}-restored`,
              label: `New instance name`,
              rules: string().max(80, ERROR_MESSAGES.MAX_BACKUPS_NAME),
              isHidden: (fieldValues: Record<string, any>) =>
                !fieldValues?.[`${vm.id}`]
            },
            {
              name: `flavor-${vm.id}`,
              type: FIELD_TYPES.SELECT,
              helperText:
                "Leaving this field empty will keep the flavor settings unchanged",
              label: `New instance flavor`,
              options: flavors?.map((flavor) =>
                getSelectOption(flavor, "name", "id")
              ),
              //   defaultValue: () =>
              //     default_flavor
              //       ? getSelectOption(default_flavor, "name", "id")
              //       : null,
              rules: object({
                label: string().nullable(),
                value: string().nullable()
              }).nullable(),
              isHidden: (fieldValues: Record<string, any>) =>
                !fieldValues?.[`${vm.id}`]
            }
          ])
          .flat(),
        // {
        //   name: "instances",
        //   type: FIELD_TYPES.MULTISELECT,
        //   label: "Instances",
        //   options: snapshotDetails?.instances.map((vm) =>
        //     getSelectOption(vm, "name", "id")
        //   ),
        //   defaultValue: snapshotDetails?.instances.map((vm) =>
        //     getSelectOption(vm, "name", "id")
        //   ),
        //   rules: array(selectOptionSchema)
        // },
        // {
        //   name: "instancesInfo",
        //   type: FIELD_TYPES.NOTES,
        //   isHidden: (fieldValues) => {
        //     return (fieldValues.instances as SelectOption[]).length !== 0;
        //   },
        //   label: "\n\n⚠️ At least one instance should be selected"
        // },
        {
          name: "restoreInfo",
          type: FIELD_TYPES.NOTES,
          label: `\n\nℹ️ Restore can only be initiated if current backup and workload are in available states.`
        }
      ]
    }
  };

  useMount(() => {
    dispatch(
      enterprisesActions.getOrganization.started({
        id: matchParams.organizationId!
      })
    );
    dispatch(
      projectsActions.getProject.started({
        regionId: matchParams.regionId!,
        id: matchParams.projectId!
      })
    );
    dispatch(
      instancesActions.getFlavors.started({
        regionId: matchParams.regionId!,
        projectId: matchParams.projectId!,
        type: FLAVOR_TYPE.INSTANCE
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.workloadInfo}`,
        action: backupsActions.getBackupsWorkloadDetails.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.snapshots}`,
        action: backupsActions.getBackupsWorkloadSnapshots.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!
        })
      })
    );
    dispatch(
      pollingActions.startPolling({
        id: `${POLL_ID_PREFIX}/${POLL_IDS.vms}`,
        action: instancesActions.getInstances.started({
          regionId: matchParams.regionId!,
          projectId: matchParams.projectId!
        })
      })
    );
  });

  useUnmount(() => {
    Object.values(POLL_IDS).forEach((id) => {
      dispatch(
        pollingActions.stopPolling({
          id: `${POLL_ID_PREFIX}/${id}`
        })
      );
    });
    dispatch(instancesActions.clear());
    dispatch(backupsActions.clear());
  });

  useEffect(() => {
    if (
      previousIsWorkloadOperationInProgress &&
      !isWorkloadOperationInProgress
    ) {
      if (!workloadDetails) {
        history(getParentPath(location.pathname));
      } else {
        dispatch(
          backupsActions.getBackupsWorkloadDetails.started({
            region: matchParams.regionId!,
            orgId: matchParams.organizationId!,
            projectId: matchParams.projectId!,
            workloadId: matchParams.workloadId!
          })
        );
      }
    }
  }, [
    history,
    dispatch,
    matchParams.projectId,
    matchParams.regionId,
    previousIsWorkloadOperationInProgress,
    isWorkloadOperationInProgress,
    workloadDetails,
    matchParams.organizationId,
    matchParams.workloadId
  ]);

  useEffect(() => {
    if (
      previousIsSnapshotsOperationInProgress &&
      !isSnapshotsOperationInProgress
    ) {
      dispatch(
        backupsActions.getBackupsWorkloadSnapshots.started({
          region: matchParams.regionId!,
          orgId: matchParams.organizationId!,
          projectId: matchParams.projectId!,
          workloadId: matchParams.workloadId!
        })
      );
    }
  }, [
    dispatch,
    isSnapshotsOperationInProgress,
    matchParams.organizationId,
    matchParams.projectId,
    matchParams.regionId,
    matchParams.workloadId,
    previousIsSnapshotsOperationInProgress
  ]);

  useEffect(() => {
    switch (activeTabIndex) {
      case TABS.BACKUPS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.BACKUPS]
          })
        });
        break;
      case TABS.VMS:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.VMS]
          })
        });
        break;
      case TABS.WORKLOAD_INFO:
        history({
          search: generateSearchString({
            tab: TAB_TITLES[TABS.WORKLOAD_INFO]
          })
        });
        break;
    }
  }, [history, activeTabIndex, dispatch]);

  useEffect(() => {
    if (
      (!areBackupsWorkloadSnapshotsMultiDeleting &&
        prevAreBackupsWorkloadSnapshotsMultiDeleting) ||
      (!isBackupsWorkloadSnapshotDeleting &&
        prevIsBackupsWorkloadSnapshotDeleting)
    ) {
      setCheckedRows([]);
      setResetSnapshotCheckboxes(true);
    }
  }, [
    areBackupsWorkloadSnapshotsMultiDeleting,
    isBackupsWorkloadSnapshotDeleting,
    prevAreBackupsWorkloadSnapshotsMultiDeleting,
    prevIsBackupsWorkloadSnapshotDeleting
  ]);

  return (
    <>
      <Head title={title} />
      {workloadDetails ? (
        <>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <s.SummaryContainer>
            <s.Title variant={"h4"} component={"h2"}>
              {title}
            </s.Title>
            <s.ActionsContainer>
              {headerMenuItems.map((menuItem, index) => (
                <Tooltip title={menuItem.title} arrow key={index}>
                  <span>
                    <IconButton
                      onClick={() => menuItem.onClick && menuItem.onClick()}
                      color={menuItem.color}
                      disabled={menuItem.disabled}
                    >
                      {menuItem.icon}
                    </IconButton>
                  </span>
                </Tooltip>
              ))}
            </s.ActionsContainer>
          </s.SummaryContainer>
          <s.SummaryRow>
            <s.DetailsTitle>ID: </s.DetailsTitle>
            <s.DetailsInfoColored>{workloadDetails.id}</s.DetailsInfoColored>
          </s.SummaryRow>
          <s.SummaryRow>
            <s.DetailsTitle>Status: </s.DetailsTitle>
            {workloadDetails.status === "available" ? (
              <s.DetailsInfoColoredSuccess>
                {workloadDetails.status}
              </s.DetailsInfoColoredSuccess>
            ) : (
              <s.DetailsInfoColoredWarning>
                {workloadDetails.status}
              </s.DetailsInfoColoredWarning>
            )}
          </s.SummaryRow>

          <s.TabsContainer>
            <Tabs value={activeTabIndex} onChange={handleChangeTab}>
              {Object.values(TAB_TITLES).map((tabTitle) => (
                <Tab key={tabTitle} label={tabTitle} />
              ))}
            </Tabs>
          </s.TabsContainer>
          {tabContent[activeTabIndex]}
          <FormDialog
            isOpened={dialog.isOpened}
            onCancel={handleCloseDialog}
            fields={dialogProps[dialog.type].fields}
            onConfirm={dialogProps[dialog.type].onConfirm}
            title={dialogProps[dialog.type].title}
            isLoading={dialogProps[dialog.type].isLoading}
            confirmButtonLabel={dialogProps[dialog.type].confirmButtonLabel}
          />
        </>
      ) : (
        <Loader text={"Loading data..."} />
      )}
    </>
  );
};
