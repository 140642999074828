import { FC, useEffect, useState } from "react";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { roundTo } from "utils/roundTo";
import * as s from "./styles";
import { GaugeChartMetricProps } from "./types";
import { useTheme } from "@mui/material";

const ANIMATION_FREQUENCY = 25;
const ANIMATION_PERCENTAGE_STEP = 2;

export const GaugeChartMetric: FC<GaugeChartMetricProps> = ({
  title,
  usedValue,
  maxValue,
  unit = ""
}: GaugeChartMetricProps) => {
  const theme = useTheme();
  const [progress, setProgress] = useState(0);
  const [timerId, setTimerId] = useState<number | null>(null);

  const percentageValue = (usedValue / maxValue) * 100;

  useEffect(() => {
    setProgress(0);
    const timerId = window.setInterval(() => {
      setProgress((prevProgress) => {
        const newValue =
          prevProgress +
          Math.min(ANIMATION_PERCENTAGE_STEP, percentageValue - prevProgress);
        return Math.min(100, newValue);
      });
    }, ANIMATION_FREQUENCY);
    setTimerId(timerId);

    return () => {
      clearInterval(timerId);
    };
  }, [percentageValue]);

  useEffect(() => {
    if (timerId && progress >= percentageValue) {
      setProgress(percentageValue);
    }
  }, [progress, percentageValue, timerId]);

  const percentageUsed = Math.round(percentageValue);

  return (
    <s.MetricContainer key={title}>
      <s.MetricTitle variant={"h6"}>{title}</s.MetricTitle>
      <Gauge
        margin={{ top: 0 }}
        value={progress}
        // innerRadius={70}
        valueMax={100}
        // value={usedValue}
        // valueMax={maxValue}
        width={170}
        height={155}
        sx={{
          [`& .${gaugeClasses.valueArc}`]: {
            fill: theme.palette.secondary.main
          },
          [`& .${gaugeClasses.valueText}`]: {
            fontSize: 30,
            transform: "translate(4px, 0px)",
            "& text": {
              fill: theme.palette.primary.main,
              fontWeight: 450,
              transition: "fill 0.3s ease",
              "&:hover": {
                fill: theme.palette.primary.dark
              }
            },
            "& tspan:last-of-type": {
              fontSize: 12,
              fill: theme.palette.text.secondary,
              transition: "fill 0.3s ease, font-size 0.3s ease",
              "&:hover": {
                fill: theme.palette.text.primary,
                fontSize: 13
              }
            }
          }
        }}
        text={() =>
          `${Math.round(percentageUsed)}%\n${roundTo(usedValue, 2)} of ${roundTo(
            maxValue,
            2
          )} ${unit}`
        }
      />
    </s.MetricContainer>
  );
};
