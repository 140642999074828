import { createSelector } from "reselect";
import { formatDate } from "utils/formatDate";
import { formatSize } from "utils/formatSize";
import { DATE_FORMATS } from "../../constants";
import { RootReducer } from "../../reducers";
import {
  backupSnapshots,
  restores,
  snapshotDetails,
  workload,
  workload2,
  workloads,
  workloads2
} from "./drafts";
import {
  BackupsWorkloadSnapshot,
  BackupsWorkload,
  BackupsWorkloadDetails,
  RETENTION_TYPES,
  RETENTION_TYPES_LABELS,
  TableBackupsWorkload,
  TableBackupsWorkloadDetails,
  TableBackupsWorkloadDetailsAssignedVMs,
  WORKLOADS_TYPES,
  WORKLOADS_TYPES_LABELS,
  BackupsWorkloadSnapshotDetails,
  TableBackupsWorkloadSnapshotDetails,
  TableBackupsWorkloadSnapshotDetailsAssignedVMs,
  RestoreFromList,
  TableRestore
} from "./types";
import { convertTimeToTimeZone, formatTime } from "utils/formatTime";

export const areBackupsWorkloadsLoadingSelector = (
  state: RootReducer
): boolean => state.backups.areBackupsWorkloadsLoading;

export const isBackupsWorkloadCreatingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsWorkloadCreating;

export const isBackupsWorkloadEditingSelector = (state: RootReducer): boolean =>
  state.backups.isBackupsWorkloadEditing;

export const isBackupsWorkloadDeletingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsWorkloadDeleting;

export const backupsWorkloadsTableSelector = createSelector(
  [
    (state: RootReducer): BackupsWorkload[] | null =>
      state.backups.backupsWorkloads
    // workloads2
  ],
  (backupsWorkloads): TableBackupsWorkload[] | null => {
    if (!backupsWorkloads) {
      return null;
    }
    return backupsWorkloads?.map((workload) => ({
      id: workload.id,
      name: workload.name,
      workload_type_id: workload.workload_type_id,
      workload_type:
        WORKLOADS_TYPES_LABELS[workload.workload_type_id as WORKLOADS_TYPES] ||
        workload.workload_type_id,
      status: workload.status,
      description: workload.description,
      snapshots_info: workload.snapshots_info,
      created_at: `${formatDate(
        new Date(workload.created_at),
        DATE_FORMATS.DATETIME
      )} UTC`,
      updated_at: workload.updated_at
        ? `${formatDate(new Date(workload.updated_at), DATE_FORMATS.DATETIME)} UTC`
        : ""
    }));
  }
);

export const isBackupsWorkloadDetailsLoadingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsWorkloadDetailsLoading;

export const backupsWorkloadDetailsSelector = createSelector(
  [
    (state: RootReducer): BackupsWorkloadDetails | null =>
      state.backups.backupsWorkloadDetails
    // workload2
  ],
  (backupsWorkloadDetails): TableBackupsWorkloadDetails | null => {
    if (!backupsWorkloadDetails) {
      return null;
    }
    return {
      id: backupsWorkloadDetails.id,
      name: backupsWorkloadDetails.name,
      description: backupsWorkloadDetails.description,
      status: backupsWorkloadDetails.status,
      error_msg: backupsWorkloadDetails.error_msg,
      created_at: backupsWorkloadDetails.created_at
        ? `${formatDate(
            new Date(backupsWorkloadDetails.created_at),
            DATE_FORMATS.DATETIME
          )} UTC`
        : "",
      updated_at: backupsWorkloadDetails?.updated_at
        ? `${formatDate(
            new Date(backupsWorkloadDetails.updated_at),
            DATE_FORMATS.DATETIME
          )} UTC`
        : "",
      workload_type_id: backupsWorkloadDetails.workload_type_id,
      workload_type_label:
        WORKLOADS_TYPES_LABELS[
          backupsWorkloadDetails.workload_type_id as WORKLOADS_TYPES
        ] || backupsWorkloadDetails.workload_type_id,
      scheduler_trust: backupsWorkloadDetails.scheduler_trust,
      availability_zone: backupsWorkloadDetails.availability_zone,
      storage_usage_total: `${formatSize(
        backupsWorkloadDetails.storage_usage.usage
      )}`,
      storage_usage_full: {
        snap_count: backupsWorkloadDetails.storage_usage.full.snap_count,
        usage: `${formatSize(backupsWorkloadDetails.storage_usage.full.usage)}`
      },
      storage_usage_incremental: {
        snap_count: backupsWorkloadDetails.storage_usage.incremental.snap_count,
        usage: `${formatSize(
          backupsWorkloadDetails?.storage_usage.incremental.usage
        )}`
      },
      instances: backupsWorkloadDetails.instances.map((instance) => ({
        name: instance.name,
        id: instance.id
      })),
      retention_policy_type:
        backupsWorkloadDetails.jobschedule.retention_policy_type,
      retention_policy_type_label:
        RETENTION_TYPES_LABELS[
          backupsWorkloadDetails.jobschedule
            .retention_policy_type as RETENTION_TYPES
        ] || backupsWorkloadDetails.jobschedule.retention_policy_type,

      retention_policy_value:
        backupsWorkloadDetails.jobschedule.retention_policy_value,
      retention_policy_value_str:
        (backupsWorkloadDetails.jobschedule
          .retention_policy_type as RETENTION_TYPES) === RETENTION_TYPES.DAYS
          ? `${backupsWorkloadDetails.jobschedule.retention_policy_value} days`
          : `${backupsWorkloadDetails.jobschedule.retention_policy_value} backups`,
      fullbackup_interval:
        backupsWorkloadDetails.jobschedule.fullbackup_interval,
      end_date: backupsWorkloadDetails.jobschedule.end_date
        ? backupsWorkloadDetails.jobschedule.end_date
        : "No End",
      start_date: backupsWorkloadDetails.jobschedule.start_date, //"10/27/2020",
      start_time: backupsWorkloadDetails.jobschedule.start_time,
      // start_time: convertTimeToTimeZone(
      //   backupsWorkloadDetails.jobschedule.start_time,
      //   backupsWorkloadDetails.jobschedule.timezone
      // ),

      schedule_interval_label: `every ${backupsWorkloadDetails.jobschedule.interval}`,
      schedule_interval: backupsWorkloadDetails.jobschedule.interval,
      schedule_enabled: backupsWorkloadDetails.jobschedule.enabled,
      timezone: backupsWorkloadDetails.jobschedule.timezone // "UTC+2",
    };
  }
);

export const backupsWorkloadDetailsAssignedVMsSelector = createSelector(
  [
    (state: RootReducer): BackupsWorkloadDetails | null =>
      state.backups.backupsWorkloadDetails
    // workload2
  ],
  (backupsWorkloadDetails): TableBackupsWorkloadDetailsAssignedVMs[] | null => {
    if (!backupsWorkloadDetails) {
      return null;
    }
    return backupsWorkloadDetails.instances.map((instance) => ({
      name: instance.name,
      id: instance.id
    }));
  }
);

export const areBackupsSnapshotsLoadingSelector = (
  state: RootReducer
): boolean => state.backups.areBackupsSnapshotsLoading;

export const backupsSnapshotsSelector = createSelector(
  [
    (state: RootReducer): BackupsWorkloadSnapshot[] | null =>
      state.backups.backupsSnapshots
    // backupSnapshots
  ],
  (backupsSnapshots) =>
    backupsSnapshots?.map((backup) => ({
      name: backup.name,
      description: backup.description,
      id: backup.id,
      status: backup.status,
      created_at: `${formatDate(
        new Date(backup.created_at),
        DATE_FORMATS.DATETIME
      )} UTC`,
      snapshot_type: backup.snapshot_type,
      workload_id: backup.workload_id,
      host: backup.host
    })) || null
);

export const isBackupsWorkloadSnapshotCreatingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsWorkloadSnapshotCreating;

export const isBackupsWorkloadSnapshotCancelingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsWorkloadSnapshotCanceling;

export const isBackupsWorkloadSnapshotDeletingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsWorkloadSnapshotDeleting;

export const areBackupsWorkloadSnapshotsMultiDeletingSelector = (
  state: RootReducer
): boolean => state.backups.areBackupsWorkloadSnapshotsMultiDeleting;

export const isBackupsSnapshotDetailsLoadingSelector = (
  state: RootReducer
): boolean => state.backups.isBackupsSnapshotDetailsLoading;

export const backupsSnapshotDetailsSelector = (
  state: RootReducer
): BackupsWorkloadSnapshotDetails | null =>
  state.backups.backupsSnapshotDetails;
// snapshotDetails;

export const tableBackupsSnapshotDetailsAssignedVMsSelector = createSelector(
  [backupsSnapshotDetailsSelector],
  (
    snapshotDetails
  ): TableBackupsWorkloadSnapshotDetailsAssignedVMs[] | null => {
    if (!snapshotDetails) {
      return null;
    }
    return snapshotDetails.instances.map((instance) => ({
      name: instance.name,
      id: instance.id,
      status: instance.status
    }));
  }
);

export const tableBackupsSnapshotDetailsSelector = createSelector(
  [backupsSnapshotDetailsSelector],
  (snapshotDetails): TableBackupsWorkloadSnapshotDetails | null => {
    if (!snapshotDetails) {
      return null;
    }
    return {
      id: snapshotDetails.id,
      name: snapshotDetails.name,
      description: snapshotDetails.description,
      created_at: snapshotDetails.created_at
        ? `${formatDate(
            new Date(snapshotDetails.created_at),
            DATE_FORMATS.DATETIME
          )} UTC`
        : "",
      updated_at: snapshotDetails.updated_at
        ? `${formatDate(
            new Date(snapshotDetails.updated_at),
            DATE_FORMATS.DATETIME
          )} UTC`
        : "",
      finished_at: snapshotDetails.finished_at
        ? formatDate(
            new Date(snapshotDetails.finished_at),
            DATE_FORMATS.DATETIME
          )
        : "",
      time_taken: `${formatTime(snapshotDetails.time_taken)}`,
      status: snapshotDetails.status,
      snapshot_type: snapshotDetails.snapshot_type,
      workload_id: snapshotDetails.workload_id,
      size: `${formatSize(snapshotDetails.size)}`,
      restore_size: snapshotDetails.restore_size
        ? `${formatSize(snapshotDetails.restore_size)}`
        : "0",
      uploaded_size: `${formatSize(snapshotDetails.uploaded_size)}`,
      progress_percent: `${snapshotDetails.progress_percent} %`,
      progress_msg: snapshotDetails.progress_msg,
      warning_msg: snapshotDetails.warning_msg,
      error_msg: snapshotDetails.error_msg
    };
  }
);

export const isSelectiveRestoreCreatingSelector = (
  state: RootReducer
): boolean => state.backups.isSelectiveRestoreCreating;

export const isInPlaceRestoreCreatingSelector = (state: RootReducer): boolean =>
  state.backups.isInPlaceRestoreCreating;

export const restoresSelector = (
  state: RootReducer
): RestoreFromList[] | null => state.backups.restores;
// ): RestoreFromList[] | null => restores;

export const tableRestoresSelector = createSelector(
  [restoresSelector],
  (restores): TableRestore[] | null =>
    restores?.map((restore) => ({
      id: restore.id,
      created_at: `${formatDate(
        new Date(restore.created_at),
        DATE_FORMATS.DATETIME
      )} UTC`,
      status: restore.status,
      name: restore.name,
      size: restore.size ? `${formatSize(restore.size)}` : "0",
      uploaded_size: restore.uploaded_size
        ? `${formatSize(restore.uploaded_size)}`
        : "0",
      progress_percent: `${restore.progress_percent} %`,
      warning_msg: restore.warning_msg,
      error_msg: restore.error_msg,
      time_taken: `${formatTime(restore.time_taken)}`,
      restore_type: restore.restore_options.restore_type
    })) || null
);

export const areRestoreLoadingSelector = (state: RootReducer): boolean =>
  state.backups.areRestoresLoading;

export const isRestoreDeletingSelector = (state: RootReducer): boolean =>
  state.backups.isRestoreDeleting;

export const isRestoreCancelingSelector = (state: RootReducer): boolean =>
  state.backups.isRestoreCanceling;
