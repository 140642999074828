import { Typography } from "@mui/material";
import styled from "styled-components";

export const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  /* box-shadow: ${({ theme }) => theme.shadows[1]}; */
  /* transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    box-shadow: ${({ theme }) => theme.shadows[3]};
  } */
`;

export const MetricTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  font-size: 18px;
  font-weight: 500;
`;
