export enum TABS {
  WORKLOAD_INFO,
  VMS,
  BACKUPS
}

export enum DIALOG_TYPES {
  CREATE_SNAPSHOT,
  CANCEL_SNAPSHOT,
  DELETE_SNAPSHOT,
  MULTI_DELETE,
  DELETE_WORKLOAD,
  EDIT_WORKLOAD,
  CHANGE_ASSIGNED_VMs,
  RESTORE
}
