import { Chip } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
`;

export const DetailsTitle = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.9rem;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const MetricsContainer = styled(Paper)`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const MetricRowContainer = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const MetricContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 190px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  /* box-shadow: ${({ theme }) => theme.shadows[1]}; */
  /* transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;
  &:hover {
    background-color: ${({ theme }) => theme.palette.action.hover};
    box-shadow: ${({ theme }) => theme.shadows[3]};
  } */
`;

export const MetricTitle = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

export const CostsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
`;

export const DatePickerTextField = styled(TextField)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const CurrencyFormControl = styled(FormControl)`
  width: 120px;
`;

export const CostsTitle = styled(Typography)<
  TypographyProps<"h5", { component: "h5" }>
>`
  margin: ${({ theme }) => theme.spacing(2, 0, 0, 2)};
`;

export const Description = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(2, 0, 0, 1)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const Description2 = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(0.5, 0, 3, 1)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
`;

export const TabsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const Tag = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(2)};
  /* font-weight: 450; */
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;
