import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography, { TypographyProps } from "@mui/material/Typography";
import styled from "styled-components";
import { LinkProps as RouterLinkProps } from "react-router-dom";
import Link, { LinkProps } from "@mui/material/Link";

export const SummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SummaryColumn = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const StyledPaper = styled(Paper)`
  overflow: auto;
  padding: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DetailsBlock = styled.div`
  flex: 1;
  margin: ${({ theme }) => theme.spacing(1)} auto;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const InfoTitle = styled(Typography)<
  TypographyProps<"h6", { component: "h6" }>
>`
  color: ${({ theme }) => theme.palette.primary.dark};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const InfoDescription = styled(Typography)`
  margin: ${({ theme }) => theme.spacing(3, 0, 1, 2)};
  color: ${({ theme }) => theme.palette.text.secondary};
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  font-size: smaller;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const DetailsTitle = styled.span`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.primary.main
      : theme.palette.text.secondary};
  font-weight: 500;
  font-size: 0.9rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const DetailsInfo = styled.span`
  margin-right: ${({ theme }) => theme.spacing(2)};
  font-size: 0.9rem;
`;

export const DetailsInfoColored = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.main};
  font-weight: 450;
`;

export const DetailsInfoColoredDark = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.primary.dark};
  font-weight: 410;
`;

export const DetailsInfoColoredSuccess = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.success.main};
  font-weight: 450;
`;

export const DetailsInfoColoredWarning = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.warning.main};
  font-weight: 450;
`;

export const DetailsInfoColoredDanger = styled(DetailsInfo)`
  color: ${({ theme }) => theme.palette.error.main};
  font-weight: 450;
`;

export const DetailsInfoGray = styled(DetailsInfo)`
  color: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.text.primary
      : theme.palette.text.secondary};
  font-weight: 450;
  font-size: 0.9rem;
`;

export const TabsContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(1.5)};
`;

export const SummaryRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacing(0.6)};
`;

export const Title = styled(Typography)<
  TypographyProps<"h2", { component: "h2" }>
>`
  margin-right: ${({ theme }) => theme.spacing(2)};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SummaryKeyValue = styled(Typography)`
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

export const Tag = styled(Chip)`
  margin-right: ${({ theme }) => theme.spacing(2)};
  background: ${({ theme }) =>
    theme.palette.mode === "dark"
      ? theme.palette.grey[900]
      : theme.palette.grey[100]};
`;

export const NoDataText = styled(Typography)`
  text-align: center;
  line-height: ${({ theme }) => theme.spacing(7)};
`;

export const LoaderContainer = styled.div`
  height: ${({ theme }) => theme.spacing(20)};
`;

export const TableLink = styled(Link)<LinkProps & RouterLinkProps>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
`;
