import actionCreatorFactory from "typescript-fsa";
import {
  CreateBackupsWorkloadParams,
  CreateBackupsWorkloadResponse,
  EditBackupsWorkloadParams,
  EditBackupsWorkloadResponse,
  DeleteBackupsWorkloadParams,
  DeleteBackupsWorkloadResponse,
  GetBackupsWorkloadSnapshotsParams,
  GetBackupsWorkloadSnapshotsResponse,
  GetBackupsWorkloadDetailsParams,
  GetBackupsWorkloadDetailsResponse,
  GetBackupsWorkloadsParams,
  GetBackupsWorkloadsResponse,
  CreateBackupsWorkloadSnapshotsParams,
  CreateBackupsWorkloadSnapshotsResponse,
  DeleteBackupsWorkloadSnapshotsParams,
  DeleteBackupsWorkloadSnapshotsResponse,
  CancelBackupsWorkloadSnapshotsParams,
  CancelBackupsWorkloadSnapshotsResponse,
  GetBackupsWorkloadSnapshotDetailsResponse,
  GetBackupsWorkloadSnapshotDetailsParams,
  CreateSelectiveRestoreResponse,
  CreateSelectiveRestoreParams,
  CreateInPlaceRestoreParams,
  CreateInPlaceRestoreResponse,
  GetRestoresParams,
  GetRestoresResponse,
  CancelRestoreParams,
  CancelRestoreResponse,
  DeleteRestoreParams,
  DeleteRestoreResponse,
  MultiDeleteBackupsWorkloadSnapshotsParams,
  MultiDeleteBackupsWorkloadSnapshotsResponse
} from "./types";

const actionCreator = actionCreatorFactory("BACKUPS");

export const getBackupsWorkloads = actionCreator.async<
  GetBackupsWorkloadsParams,
  GetBackupsWorkloadsResponse,
  unknown
>("GET_BACKUPS_WORKLOADS");

export const getBackupsWorkloadDetails = actionCreator.async<
  GetBackupsWorkloadDetailsParams,
  GetBackupsWorkloadDetailsResponse,
  unknown
>("GET_BACKUPS_WORKLOAD_DETAILS");

export const createBackupsWorkload = actionCreator.async<
  CreateBackupsWorkloadParams,
  CreateBackupsWorkloadResponse,
  unknown
>("CREATE_BACKUPS_WORKLOAD");

export const editBackupsWorkload = actionCreator.async<
  EditBackupsWorkloadParams,
  EditBackupsWorkloadResponse,
  unknown
>("EDIT_BACKUPS_WORKLOAD");

export const deleteBackupsWorkload = actionCreator.async<
  DeleteBackupsWorkloadParams,
  DeleteBackupsWorkloadResponse,
  unknown
>("DELETE_BACKUPS_WORKLOAD");

export const getBackupsWorkloadSnapshots = actionCreator.async<
  GetBackupsWorkloadSnapshotsParams,
  GetBackupsWorkloadSnapshotsResponse,
  unknown
>("GET_BACKUPS_WORKLOAD_SNAPSHOTS");

export const createBackupsWorkloadSnapshot = actionCreator.async<
  CreateBackupsWorkloadSnapshotsParams,
  CreateBackupsWorkloadSnapshotsResponse,
  unknown
>("CREATE_BACKUPS_WORKLOAD_SNAPSHOTS");

export const deleteBackupsWorkloadSnapshot = actionCreator.async<
  DeleteBackupsWorkloadSnapshotsParams,
  DeleteBackupsWorkloadSnapshotsResponse,
  unknown
>("DELETE_BACKUPS_WORKLOAD_SNAPSHOTS");

export const multiDeleteBackupsWorkloadSnapshots = actionCreator.async<
  MultiDeleteBackupsWorkloadSnapshotsParams,
  MultiDeleteBackupsWorkloadSnapshotsResponse,
  unknown
>("MULTI_DELETE_BACKUPS_WORKLOAD_SNAPSHOTS");

export const cancelBackupsWorkloadSnapshot = actionCreator.async<
  CancelBackupsWorkloadSnapshotsParams,
  CancelBackupsWorkloadSnapshotsResponse,
  unknown
>("CANCEL_BACKUPS_WORKLOAD_SNAPSHOTS");

export const getBackupsWorkloadSnapshotDetails = actionCreator.async<
  GetBackupsWorkloadSnapshotDetailsParams,
  GetBackupsWorkloadSnapshotDetailsResponse,
  unknown
>("GET_BACKUPS_WORKLOAD_SNAPSHOT_DETAILS");

export const getRestores = actionCreator.async<
  GetRestoresParams,
  GetRestoresResponse,
  unknown
>("GET_RESTORES");

export const createSelectiveRestore = actionCreator.async<
  CreateSelectiveRestoreParams,
  CreateSelectiveRestoreResponse,
  unknown
>("CREATE_SELECTIVE_RESTORE");

export const createInPlaceRestore = actionCreator.async<
  CreateInPlaceRestoreParams,
  CreateInPlaceRestoreResponse,
  unknown
>("CREATE_INPLACE_RESTORE");

export const cancelRestore = actionCreator.async<
  CancelRestoreParams,
  CancelRestoreResponse,
  unknown
>("CANCEL_RESTORE");

export const deleteRestore = actionCreator.async<
  DeleteRestoreParams,
  DeleteRestoreResponse,
  unknown
>("DELETE_RESTORE");

//clear

export const clearBackupsWorkloads = actionCreator<void>(
  "CLEAR_BACKUPS_WORKLOADS"
);
export const clearWorkloadDetails = actionCreator<void>(
  "CLEAR_WORKLOAD_DETAILS"
);
export const clearBackupsSnapshots = actionCreator<void>(
  "CLEAR_BACKUPS_SNAPSHOTS"
);
export const clear = actionCreator<void>("CLEAR");
