export enum DIALOG_TYPES {
  CREATE_WORKLOAD,
  EDIT_WORKLOAD,
  DELETE_WORKLOAD
}

export enum TABS {
  WORKLOADS,
  BACKUPS
}
